import {Route, Routes} from "react-router-dom";
import MastersView from "../views/MasterView";
import ZoneList from "../views/zones/ZoneList";
import InterlocutorTypeList from "../views/interlocutorTypes/InterlocutorTypeList";
import WeekList from "../views/weeks/WeekList";
import Notfound from "../layouts/404/404";
import ReferenceList from "../views/references/ReferenceList";
import Dashboard from "../views/dashboard/Dashboard";
import useRole from "../../hooks/useRole";
import Roles from "../../helpers/roles";
import UserList from "../views/users/UserList";
import InterlocutorList from "../views/interlocutors/InterlocutorList";
import VehicleList from "../views/vehicles/VehicleList";
import InvoiceList from "../views/invoices/InvoiceList";
import PriceList from "../views/prices/PriceList";
import ConsecutiveList from "../views/consecutive/ConsecutiveList";
import SettlesSelect from "../views/settles/SettleSelect";
import SettlesFind from "../views/settles/SettlesFind";
import SettlesCreate from "../views/settles/SetlesCreate"
import SettleReport from "../views/settles/SettleReport";
import Const from "../views/const/Const";


const MainRoutes = () => {

    const {role} = useRole();

    return (
        <Routes>
            <Route exact path="/" element={<Dashboard/>}/>
            <Route path="/references" element={<ReferenceList/>}/>
            <Route path="/invoices" element={<InvoiceList/>}/>

            {role === Roles.Admin &&
                <>
                    <Route path="/settles" element={<SettlesSelect/>}/>
                    <Route path="/settles/find" element={<SettlesFind/>}/>
                    <Route path="/settles/create" element={<SettlesCreate/>}/>
                    <Route path="/settles/reports" element={<SettleReport/>}/>

                    <Route path="/users" element={<UserList/>}/>

                    <Route path="/masters" element={<MastersView/>}/>

                    <Route path="/masters/zones" element={<ZoneList/>}/>

                    <Route path="/masters/interlocutor-type" element={<InterlocutorTypeList/>}/>

                    <Route path="/masters/week" element={<WeekList/>}/>

                    <Route path="/masters/vehicle" element={<VehicleList/>}/>

                    <Route path="/masters/interlocutor" element={<InterlocutorList/>}/>

                    <Route path="/masters/prices" element={<PriceList/>}/>

                    <Route path="/masters/consecutive" element={<ConsecutiveList/>}/>

                    <Route path="/masters/const" element={<Const/>}/>

                </>
            }
            <Route path="*" element={<Notfound/>}/>
        </Routes>
    );
}

export default MainRoutes;