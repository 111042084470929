import {useEffect, useState} from "react";

export default function useRole() {
    const getRole = () => {
        const roleString = localStorage.getItem('role');
        return JSON.parse(roleString)
    };

    const [role, setRole] = useState(getRole);

    useEffect(() => {
        setRole(getRole())
    }, [setRole]);

    const saveRole = userRole => {
        localStorage.setItem('role', JSON.stringify(userRole));
        setRole(role);

    };

    const clearRole = () => {
        localStorage.removeItem('role');
        setRole(null)
    }

    return {
        role,
        setRole: saveRole,
        clearRole: clearRole,
    }
}
