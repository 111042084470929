import {grey, red} from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: '#487c3c',
        },
        secondary: {
            main: '#fff',
        },
        background: {
            default: grey[100]
        },
        error: {
            main: red.A400,
        },
    },
});

export default theme;