import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import {useCallback, useEffect, useState} from "react";
import {toast} from "react-toastify";
import UserService from "../../../services/user.service";
import RoleService from "../../../services/role.service";
import interlocutorService from "../../../services/interlocutor.service";
import {capitalizeFirstLetter} from "../../../helpers/utils";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    paddingBottom: '10px'
};

const inputStyle = {
    marginBottom: '15px'
}

const UserModal = props => {

    const isCreateModal = props.modalType === 'CREATE';
    const [user, setUser] = useState({...props.user})
    const [roleTypes, setRolesTypes] = useState([]);
    const [employeesList, setEmployeesList] = useState([]);

    const MODAL_TITLE = isCreateModal ? 'Crear Usuario' : 'Editar Usuario'
    const MODAL_CONFIRM_BUTTON = isCreateModal ? 'Crear' : 'Guardar'

    const inputChangeHandler = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setUser(user => {
            return {
                ...user,
                [name]: value
            }
        })
    }

    const submitUserHandler = async e => {
        e.preventDefault();
        try {
            if (isCreateModal) {
                await UserService.createUser(user);
            } else {
                await UserService.updateUser(user);
            }

            const successMessage = isCreateModal ? 'Se ha creado correctamente' : 'Se ha actualizado correctamente';
            toast.success(successMessage, {delay: 200});
            closeModalHandler();
        } catch (e) {
            console.error(e);
            toast.error('Ocurrió un error, vuelva a intentar', {delay: 200});
        }
    }

    const fetchEmployeesHandler = useCallback(async () => {
        try {
            const employees = await interlocutorService.getEmployees();
            setEmployeesList(employees);
        } catch (error) {
            console.error(error.message);
        }
    }, []);

    const fetchRoleTypesHandler = useCallback(async () => {
        try {
            const roles = await RoleService.getRoles();
            setRolesTypes(roles);
        } catch (error) {
            console.error(error.message);
        }
    }, []);

    useEffect(() => {
        if(props.open) setUser({...props.user})
    }, [props.user, props.open]);

    useEffect(() => {
        void fetchRoleTypesHandler();
    }, [fetchRoleTypesHandler])

   useEffect(() => {
        void fetchEmployeesHandler();
    }, [fetchEmployeesHandler])

    const closeModalHandler = () => props.onClose();

    return (
        <Modal
            open={props.open}
            onClose={closeModalHandler}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{marginBottom: '30px'}}>
                    {MODAL_TITLE}
                </Typography>
                <form onSubmit={submitUserHandler}>
                    <TextField name="username" label="Nombre de usuario"
                               variant="outlined"
                               size="small"
                               value={user.username}
                               onChange={inputChangeHandler}
                               fullWidth
                               sx={inputStyle}/>
                    <TextField name="password"
                               label="Contraseña"
                               variant="outlined"
                               size="small"
                               onChange={inputChangeHandler}
                               fullWidth
                               type="password"
                               sx={inputStyle}/>
                    <TextField
                        name="roleId"
                        select
                        label="Rol"
                        onChange={inputChangeHandler}
                        value={user.roleId}
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={inputStyle}
                    >
                        {roleTypes.map((role) => (
                            <MenuItem key={role.id} value={role.id}>
                                {capitalizeFirstLetter(role.name)}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        name="employeeId"
                        select
                        label="Empleado"
                        onChange={inputChangeHandler}
                        value={user.employeeId}
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={inputStyle}
                    >
                        {employeesList.map((employee) => (
                            <MenuItem key={employee.id} value={employee.id}>
                                {`${employee.identificationType}: ${employee.identification} / ${employee.name} (${employee.interlocutorType?.type})`}
                            </MenuItem>
                        ))}
                    </TextField>
                    {
                        !isCreateModal &&
                        <TextField name="active"
                                   select
                                   label="Estado"
                                   variant="outlined"
                                   size="small"
                                   fullWidth
                                   value={user.active ? 1 : 0}
                                   onChange={inputChangeHandler}
                                   sx={inputStyle}>
                            <MenuItem key={1} value={1}>Activo</MenuItem>
                            <MenuItem key={0} value={0}>Inactivo</MenuItem>
                        </TextField>
                    }
                    <Box sx={{float: 'right'}}>
                        <Button variant="outlined" color="error" onClick={closeModalHandler}
                                sx={{marginRight: '10px'}}>Cancelar</Button>
                        <Button variant="contained" type="submit">{MODAL_CONFIRM_BUTTON}</Button>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
}

export default UserModal;