import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import StyledTableCell from "../../layouts/table/StyledTableCell";
import StyledTableRow from "../../layouts/table/StyledTableRow";
import {Card, CardContent, CardHeader} from "@mui/material";
import Typography from "@mui/material/Typography";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Button from "@mui/material/Button";
import React, {useCallback, useEffect, useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteItemModal from "../DeleteItemModal";
import {toast} from "react-toastify";
import {convertDate} from "../../../helpers/utils";
import InterlocutorService from "../../../services/interlocutor.service";
import InterlocutorModal from "./InterlocutorModal";


const initialInterlocutor = {
    id: '',
    name: '',
    identificationType: '',
    identification: '',
    startDate: '',
    interlocutorTypeId: '',
    active: true
}

const InterlocutorList = () => {

    const [interlocutors, setInterlocutors] = useState([]);
    const [selectedInterlocutor, setSelectedInterlocutor] = useState(initialInterlocutor)
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    //modals
    const [openCreateInterlocutorModal, setOpenCreateInterlocutorModal] = useState(false);
    const [openEditInterlocutorModal, setOpenEditInterlocutorModal] = useState(false);
    const [openDeleteInterlocutorModal, setOpenDeleteInterlocutorModal] = useState(false);

    const fetchInterlocutorsHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null)
        try {
            const interlocutorList = await InterlocutorService.getInterlocutors();
            setInterlocutors(interlocutorList);

        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false);
    }, []);

    const removeInterlocutorHandler = async () => {
        try {
            await InterlocutorService.removeInterlocutor(selectedInterlocutor.id);
            toast.success('Se ha eliminado correctamente');
            toggleModal('remove', false)
        } catch (e) {
            console.error(e);
            toast.error('There was an error trying to remove this interlocutor');
        }
    };

    const openCreateInterlocutorHandler = () => {
        setSelectedInterlocutor(initialInterlocutor)
        toggleModal('create', true);
    }

    const openEditInterlocutorHandler = (interlocutor) => {
        setSelectedInterlocutor(selectedInterlocutor => interlocutor)
        toggleModal('edit', true);
    }

    const openRemoveInterlocutorHandler = (interlocutor) => {
        setSelectedInterlocutor(interlocutor)
        toggleModal('remove', true);
    }

    const toggleModal = (modalType, open) => {
        switch (modalType) {
            case 'create':
                setOpenCreateInterlocutorModal(open);
                break;
            case 'edit':
                setOpenEditInterlocutorModal(open);
                break;
            case 'remove':
                setOpenDeleteInterlocutorModal(open);
                break;
            default:
                console.error(`Modal type ${modalType} is not valid`);
        }

    }

    useEffect(() => {
        if (!openCreateInterlocutorModal && !openDeleteInterlocutorModal && !openEditInterlocutorModal) {
            void fetchInterlocutorsHandler();
        }

    }, [fetchInterlocutorsHandler, openCreateInterlocutorModal, openDeleteInterlocutorModal,
        openEditInterlocutorModal])


    return (
        <>
            <Card>
                <CardHeader
                    title={
                        <Typography variant='h4'>
                            Interlocutores
                        </Typography>
                    }
                    action={
                        <Button variant="outlined" startIcon={<ControlPointIcon/>}
                                onClick={openCreateInterlocutorHandler}>
                            Añadir nuevo
                        </Button>
                    }
                >
                </CardHeader>
                <CardContent>
                    {isLoading && interlocutors.length <= 0 && <p>Cargando interlocutores...</p>}
                    {!isLoading && !error && interlocutors.length === 0 && <p>No se han creado interlocutores.</p>}
                    {error && <p> {error} </p>}
                    {interlocutors.length > 0 &&
                        <TableContainer component={Paper} sx={{maxWidth: '100%'}}>
                            <Table sx={{minWidth: 390}}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="right">Tipo de interlocutor</StyledTableCell>
                                        <StyledTableCell>Nombre</StyledTableCell>
                                        <StyledTableCell align="right">Tipo de identificación</StyledTableCell>
                                        <StyledTableCell align="right">Identificación</StyledTableCell>
                                        <StyledTableCell align="right">Fecha de inicio</StyledTableCell>
                                        <StyledTableCell align="right">Estado</StyledTableCell>
                                        <StyledTableCell align="right"/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {interlocutors.map((interlocutor) => (
                                        <StyledTableRow
                                            key={interlocutor.id}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell align="right">{interlocutor.interlocutorType?.type}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {interlocutor.name}
                                            </TableCell>
                                            <TableCell align="right">{interlocutor.identificationType}</TableCell>
                                            <TableCell align="right">{interlocutor.identification}</TableCell>
                                            <TableCell align="right">{convertDate(interlocutor.startDate)}</TableCell>
                                            
                                            <TableCell
                                                align="right">{interlocutor.active ? 'Activo' : 'Inactivo'}</TableCell>
                                            <TableCell align="right">
                                                <Button onClick={() => openEditInterlocutorHandler(interlocutor)}>
                                                    <EditIcon/>
                                                </Button>
                                                <Button onClick={() => openRemoveInterlocutorHandler(interlocutor)}>
                                                    <DeleteIcon color={'warning'}/>
                                                </Button>
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                </CardContent>
            </Card>

            {/*modals*/}
            <InterlocutorModal open={openCreateInterlocutorModal}
                               modalType="CREATE"
                               interlocutor={selectedInterlocutor}
                               onClose={() => toggleModal('create', false)}/>
            <InterlocutorModal open={openEditInterlocutorModal}
                               modalType="UPDATE"
                               interlocutor={selectedInterlocutor}
                               onClose={() => toggleModal('edit', false)}/>
            <DeleteItemModal open={openDeleteInterlocutorModal}
                             onClose={() => toggleModal('remove', false)}
                             onRemove={removeInterlocutorHandler}
                             itemName={selectedInterlocutor.name}
                             item={"interlocutor"}/>
        </>

    )
}

export default InterlocutorList;