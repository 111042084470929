import Settings from '../settings/ConfigServer';
import storageUtils from "../helpers/tokenUtils";

const GET_WEEKS_API_URL = `${Settings.urlLocal}/master/weeks`;
const GET_WEEK_BY_YEAR_API_URL = `${Settings.urlLocal}/master/week`
const CREATE_WEEK_API_URL = `${Settings.urlLocal}/master/weeks/create`;
const UPDATE_WEEK_API_URL = `${Settings.urlLocal}/master/weeks/update`;
const REMOVE_WEEK_API_URL = `${Settings.urlLocal}/master/weeks/delete`;

const WeekService = {}

WeekService.getWeeks = async () => {
    const response = await fetch(GET_WEEKS_API_URL, {
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    });
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response.map(week => {
        return week
    });
}

WeekService.getWeekByYear = async(weekSelected) => {
    const response = await fetch(GET_WEEK_BY_YEAR_API_URL, {
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        },
        body: JSON.stringify(weekSelected)
    });
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response.map(week => {
        const dateFromRes = week.startDate.split('T')[0]
        const dateToRes = week.endDate.split('T')[0]
        let responseWeek = {
            id: week.id,
            dateFromRes,
            dateToRes
        }
        return responseWeek
    });
}

WeekService.createWeek = async (week) => {

    const response = await fetch(CREATE_WEEK_API_URL, {
        method: 'POST',
        body: JSON.stringify(week),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    })

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;
}

WeekService.updateWeek = async week => {

    const response = await fetch(`${UPDATE_WEEK_API_URL}/${week.id}`, {
        method: 'PUT',
        body: JSON.stringify(week),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    })

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;
}

WeekService.removeWeek = async weekId => {

    const response = await fetch(`${REMOVE_WEEK_API_URL}/${weekId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    });

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;

}

export default WeekService;