import React from 'react'
import { Card, CardContent, CardHeader, Box, TextField, Button } from "@mui/material"
import Typography from '@mui/material/Typography'
import ConstService from "../../../services/const.service"
import {toast} from "react-toastify";

const Const = () => {
    //States
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [constList, setConstList] = React.useState([])
    const [values, setValues] = React.useState({
        constUnid: "",
        constCentena: "",
        constUnMil: "",
        constMillon: "",
        constPulg: "",
        constVol: ""
    })

    //fucntions
    const inputChangeHandler = e => {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        setValues(values => {
            return {
                ...values,
                [name]: value
            }
        })
    }

    const fetchConsecutiveHandler = React.useCallback(async () => {
        setLoading(true);
        setError(null)
        try {
            const constList = await ConstService.getConst();
            setConstList(constList);
            
            setValues({
                ...values,
                constUnid:constList[1].value,
                constCentena: constList[0].value,
                constUnMil: constList[2].value,
                constMillon: constList[3].value,
                constPulg: constList[4].value,
                constVol: constList[5].value
            })
        } catch (error) {
            setError(error.message);
        }
        setLoading(false);
        
    }, []);

    const handleSubmit = async(e) => {
        e.preventDefault()
        try {
            cleaningForm()
            await ConstService.createConst(values)
            toast.success('Se ha creado correctamente', {delay: 200})
        } catch (e) {
            console.error(e)
            toast.error(e.message, {delay: 200})
        }
    }

    const cleaningForm = () => {
        setValues({
            constUnid: "",
            constCentena: "",
            constUnMil: "",
            constMillon: "",
            constPulg: "",
            constVol: ""
        })
    }

    React.useEffect(() => {
        void fetchConsecutiveHandler()
    }, [fetchConsecutiveHandler])

    return (
        <div>
            <Card>
                <CardHeader
                    title={
                        <Typography variant='h5'>
                            Constantes
                        </Typography>
                    }
                ></CardHeader>
                <Box sx={{ width: '97%', borderBottom: '1px solid #DEDEDE', margin: '0 auto' }} />
                <CardContent>
                    <Box>
                    {loading && constList.length <= 0 && <p>Cargando constantes...</p>}
                    {!loading && !error && constList.length === 0 && <p>No se han creado constantes.</p>}
                        <Box>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <TextField
                                    fullWidth
                                    id={'constUnid'}
                                    name={'constUnid'}
                                    label={'Constante unidad'}
                                    variant="outlined"
                                    value={values.constUnid}
                                    onChange={inputChangeHandler}
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    fullWidth
                                    id={'constCentena'}
                                    name={'constCentena'}
                                    label={'Constante centena'}
                                    variant="outlined"
                                    value={values.constCentena}
                                    onChange={inputChangeHandler}
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    fullWidth
                                    id={'constUnMil'}
                                    name={'constUnMil'}
                                    label={'Constante unidad de mil'}
                                    variant="outlined"
                                    value={values.constUnMil}
                                    onChange={inputChangeHandler}
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    fullWidth
                                    id={'constMillon'}
                                    name={'constMillon'}
                                    label={'Constante millón'}
                                    variant="outlined"
                                    value={values.constMillon}
                                    onChange={inputChangeHandler}
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    fullWidth
                                    id={'constPulg'}
                                    name={'constPulg'}
                                    label={'Constante pulgada'}
                                    variant="outlined"
                                    value={values.constPulg}
                                    onChange={inputChangeHandler}
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    fullWidth
                                    id={'constVol'}
                                    name={'constVol'}
                                    label={'Constante volumen'}
                                    variant="outlined"
                                    value={values.constVol}
                                    onChange={inputChangeHandler}
                                    sx={{ mb: 2 }}
                                />
                                <Button variant="outlined" type={'button'}>Guardar</Button>
                            </form>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </div>
    )
}

export default Const