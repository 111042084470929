import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import {useCallback, useEffect, useState} from "react";
import {toast} from "react-toastify";
import PriceService from "../../../services/price.service";
import interlocutorService from "../../../services/interlocutor.service";
import referenceService from "../../../services/reference.service";
import {FormControl, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    paddingBottom: '10px'
};

const inputStyle = {
    marginBottom: '15px'
}

const PriceModal = props => {

    const isCreateModal = props.modalType === 'CREATE';
    const [price, setPrice] = useState({...props.price});
    const [interlocutors, setInterlocutor] = useState([]);
    const [references, setReference] = useState([]);

    const MODAL_TITLE = isCreateModal ? 'Crear Precio Cliente' : 'Editar Precio Cliente'
    const MODAL_CONFIRM_BUTTON = isCreateModal ? 'Crear' : 'Guardar'

    const inputChangeHandler = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setPrice(price => {
            return {
                ...price,
                [name]: value
            }
        })
    }

    const submitPriceHandler = async e => {
        e.preventDefault();
        try {
            if (isCreateModal) {
                await PriceService.createPrice(price);
            } else {
                await PriceService.updatePrice(price);
            }

            const successMessage = isCreateModal ? 'Se ha creado correctamente' : 'Se ha actualizado correctamente';
            toast.success(successMessage, {delay: 200});
            closeModalHandler();
        } catch (e) {
            console.error(e);
            toast.error('Ocurrió un error, vuelva a intentar', {delay: 200});
        }
    }

    const fetchInterlocutorsHandler = useCallback(async () => {
        try {
            const interlocutorList = await interlocutorService.getCustomers();
            setInterlocutor(interlocutorList);
        } catch (error) {
            console.error(error.message);
        }
    }, []);

    const fetchReferencesHandler = useCallback(async () => {
        try {
            const referenceList = await referenceService.getReferences();
            setReference(referenceList);
        } catch (error) {
            console.error(error.message);
        }
    }, []);

    useEffect(() => {
        setPrice({...props.price})
    }, [props.price]);

    useEffect(() => {
        void fetchInterlocutorsHandler();
    }, [fetchInterlocutorsHandler]);

    useEffect(() => {
        void fetchReferencesHandler();
    }, [fetchReferencesHandler]);

    const closeModalHandler = () => props.onClose();

    return (
        <Modal
            open={props.open}
            onClose={closeModalHandler}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{marginBottom: '30px'}}>
                    {MODAL_TITLE}
                </Typography>
                <form onSubmit={submitPriceHandler}>
                    <TextField
                        name="interlocutorId"
                        select
                        label="Interlocutor"
                        onChange={inputChangeHandler}
                        value={price.interlocutorId}
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={inputStyle}
                    >
                        {interlocutors.map((interlocutor) => (
                            <MenuItem key={interlocutor.id} value={interlocutor.id}>
                                {`${interlocutor.identificationType}: ${interlocutor.identification} / ${interlocutor.name}`}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        name="referenceId"
                        select
                        label="Referencia"
                        onChange={inputChangeHandler}
                        value={price.referenceId}
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={inputStyle}
                    >
                        {references.map((reference) => (
                            <MenuItem key={reference.id} value={reference.id}>
                                {`${reference.type} / ${reference.code}: ${reference.name}`}
                            </MenuItem>
                        ))}
                    </TextField>
                    <FormControl fullWidth sx={inputStyle}>
                        <InputLabel htmlFor="salePrice">Precio cliente</InputLabel>
                        <OutlinedInput
                            id="salePrice"
                            name="salePrice"
                            size="small"
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            label="Precio cliente"
                            type="number"
                            value={price.salePrice}
                            onChange={inputChangeHandler}
                        />
                    </FormControl>
                    {
                        !isCreateModal &&
                        <TextField name="active"
                                   select
                                   label="Estado"
                                   variant="outlined"
                                   size="small"
                                   fullWidth
                                   value={price.active ? 1 : 0}
                                   onChange={inputChangeHandler}
                                   sx={inputStyle}>
                            <MenuItem key={1} value={1}>Activo</MenuItem>
                            <MenuItem key={0} value={0}>Inactivo</MenuItem>
                        </TextField>
                    }
                    <Box sx={{float: 'right'}}>
                        <Button variant="outlined" color="error" onClick={closeModalHandler}
                                sx={{marginRight: '10px'}}>Cancelar</Button>
                        <Button variant="contained" type="submit">{MODAL_CONFIRM_BUTTON}</Button>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
}

export default PriceModal;