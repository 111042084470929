import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import InterlocutorTypeService from "../../../services/interlocutorType.service";
import MenuItem from "@mui/material/MenuItem";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    paddingBottom: '10px'
};

const inputStyle = {
    marginBottom: '15px'
}

const InterlocutorTypeModal = props => {

    const isCreateModal = props.modalType === 'CREATE';
    const [interlocutorType, setInterlocutorType] = useState({...props.interlocutorType})

    const MODAL_TITLE = isCreateModal ? 'Crear Tipo Interlocutor' : 'Editar Tipo de interlocutor'
    const MODAL_CONFIRM_BUTTON = isCreateModal ? 'Crear' : 'Guardar'

    const inputChangeHandler = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setInterlocutorType(interlocutorType => {
            return {
                ...interlocutorType,
                [name]: value
            }
        })
    }

    const submitInterlocutorTypeHandler = async e => {
        e.preventDefault();
        try {
            if(isCreateModal) {
                await InterlocutorTypeService.createInterlocutorType(interlocutorType, props.interlocutorTypesSize);
            } else {
                await InterlocutorTypeService.updateInterlocutorType(interlocutorType);
            }

            const successMessage = isCreateModal ? 'Se ha creado correctamente' : 'Se ha actualizado correctamente';
            toast.success(successMessage, {delay: 200});
            closeModalHandler();
        } catch (e) {
            console.error(e);
            toast.error('Ocurrió un error, vuelva a intentar', {delay: 200});
        }
    }

    useEffect(() => {
        if(props.open) setInterlocutorType({...props.interlocutorType})
    }, [props.interlocutorType, props.open]);

    const closeModalHandler = () => props.onClose();

    return (
        <Modal
            open={props.open}
            onClose={closeModalHandler}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{marginBottom: '30px'}}>
                    {MODAL_TITLE}
                </Typography>
                <form onSubmit={submitInterlocutorTypeHandler}>
                    <TextField name="type"
                               label="Tipo de interlocutor"
                               variant="outlined"
                               size="small"
                               value={interlocutorType.type}
                               onChange={inputChangeHandler}
                               fullWidth
                               sx={inputStyle}/>
                    {
                        !isCreateModal &&
                        <TextField name="active"
                                   select
                                   label="Estado"
                                   variant="outlined"
                                   size="small"
                                   fullWidth
                                   value={interlocutorType.active ? 1 : 0}
                                   onChange={inputChangeHandler}
                                   sx={inputStyle}>
                            <MenuItem key={1} value={1}>Activo</MenuItem>
                            <MenuItem key={0} value={0}>Inactivo</MenuItem>
                        </TextField>
                    }
                    <Box sx={{float: 'right'}}>
                        <Button variant="outlined" color="error" onClick={closeModalHandler}
                                sx={{marginRight: '10px'}}>Cancelar</Button>
                        <Button variant="contained" type="submit">{MODAL_CONFIRM_BUTTON}</Button>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
}

export default InterlocutorTypeModal;