import Settings from '../settings/ConfigServer';
import storageUtils from "../helpers/tokenUtils";

const GET_PRICES_API_URL = `${Settings.urlLocal}/master/prices`;
const GET_ACTIVE_PRICES_API_URL = `${Settings.urlLocal}/master/active-prices`;
const CREATE_PRICE_API_URL = `${Settings.urlLocal}/master/prices/create`;
const UPDATE_PRICE_API_URL = `${Settings.urlLocal}/master/prices/update`;
const REMOVE_PRICE_API_URL = `${Settings.urlLocal}/master/prices/delete`;

const PriceService = {}

PriceService.getPrices = async () => {
    const response = await fetch(GET_PRICES_API_URL, {
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    });
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response;
}

PriceService.getActivePrices = async () => {
    const response = await fetch(GET_ACTIVE_PRICES_API_URL, {
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`
        }
    });
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response;
}

PriceService.createPrice = async price => {
    const response = await fetch(CREATE_PRICE_API_URL, {
        method: 'POST',
        body: JSON.stringify(price),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    })

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;
}

PriceService.updatePrice = async price => {
    const response = await fetch(`${UPDATE_PRICE_API_URL}/${price.id}`, {
        method: 'PUT',
        body: JSON.stringify(price),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    })

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;
}

PriceService.removePrice = async priceId => {

    const response = await fetch(`${REMOVE_PRICE_API_URL}/${priceId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    });

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;

}

export default PriceService;