import React from 'react'
import { Card, CardContent, CardHeader, Box } from "@mui/material"
import Typography from '@mui/material/Typography'
import AssignmentIcon from '@mui/icons-material/Assignment'
// import PlagiarismIcon from '@mui/icons-material/Plagiarism'
import BarChartIcon from '@mui/icons-material/BarChart';
import { useNavigate } from 'react-router-dom'

const SettleSelect = () => {
    //Router
    const navigate = useNavigate()

    //functions
    const redirectToLiquidar = () => {
        console.log('liquidar')
        navigate('/settles/create')
    }

    // const redirectToFindLiquidar = () => {
    //     console.log('find liquidar')
    //     navigate('/settles/find')
    // }

    const redirectToReportSettles = () => {
        console.log('find liquidar')
        navigate('/settles/reports')
    }

    return (
        <>
            <Card>
                <CardHeader
                    title={
                        <Typography variant='h5'>
                            Liquidaciones
                        </Typography>
                    }
                >
                </CardHeader>
                <Box sx={{ width: '97%', borderBottom: '1px solid #DEDEDE', margin: '0 auto' }} />
                <CardContent>
                    <Box
                        sx={{ display: 'flex' }}
                    >
                        <Box component={'div'}
                            sx={{
                                width: '50%',
                                margin: '1em'
                            }}
                            onClick={() => redirectToLiquidar()}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    border: 'solid 1px #DEDEDE',
                                    borderRadius: '0.5em',
                                    padding: '2em'
                                }}
                            >
                                <AssignmentIcon
                                    sx={{
                                        width: 50,
                                        height: 50,
                                        color: '#AAAAAA'
                                    }}
                                />
                                <Typography variant='p' sx={{ color: '#2e7d32', mt: 2 }}>Liquidar</Typography>
                            </Box>
                        </Box>
                        {/* <Box component={'div'}
                            sx={{
                                width: '50%',
                                margin: '1em'
                            }}
                            onClick={() => redirectToFindLiquidar()}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    border: 'solid 1px #DEDEDE',
                                    borderRadius: '0.5em',
                                    padding: '2em'
                                }}
                            >
                                <PlagiarismIcon
                                    sx={{
                                        width: 50,
                                        height: 50,
                                        color: '#AAAAAA'
                                    }}
                                />
                                <Typography variant='p' sx={{ color: '#2e7d32', mt: 2 }}>Buscar</Typography>
                            </Box>
                        </Box> */}
                        <Box component={'div'}
                            sx={{
                                width: '50%',
                                margin: '1em'
                            }}
                            onClick={() => redirectToReportSettles()}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    border: 'solid 1px #DEDEDE',
                                    borderRadius: '0.5em',
                                    padding: '2em'
                                }}
                            >
                                <BarChartIcon
                                    sx={{
                                        width: 50,
                                        height: 50,
                                        color: '#AAAAAA'
                                    }}
                                />
                                <Typography variant='p' sx={{ color: '#2e7d32', mt: 2 }}>Reportes</Typography>
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}

export default SettleSelect