import Settings from '../settings/ConfigServer';
import storageUtils from "../helpers/tokenUtils";

const GET_USERS_API_URL = `${Settings.urlLocal}/users`;
const CREATE_USER_API_URL = `${Settings.urlLocal}/users/create`;
const UPDATE_USER_API_URL = `${Settings.urlLocal}/users/update`;
const REMOVE_USER_API_URL = `${Settings.urlLocal}/users/delete`;

const UserService = {}

UserService.getUsers = async () => {
    const response = await fetch(GET_USERS_API_URL, {
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    });
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response;
}

UserService.createUser = async user => {
    console.log(user)
    const response = await fetch(CREATE_USER_API_URL, {
        method: 'POST',
        body: JSON.stringify(user),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    })

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;
}

UserService.updateUser = async user => {
    const response = await fetch(`${UPDATE_USER_API_URL}/${user.id}`, {
        method: 'PUT',
        body: JSON.stringify(user),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    })

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;
}

UserService.removeUser = async userId => {

    const response = await fetch(`${REMOVE_USER_API_URL}/${userId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    });

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;

}

export default UserService;