import Typography from "@mui/material/Typography";
import Copyright from "../copyright/Copyright";
import Box from "@mui/material/Box";

const Footer = () => {
    return (
        <Box
            sx={{
                background: (theme) => theme.palette.secondary.main,
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                width: "100%",
                paddingTop: '30px',
                paddingBottom: '10px',
                margin: 0
            }} component="footer">
            <Typography
                variant="subtitle1"
                align="center"
                color="text.secondary"
                component="p"
            >
                Control, gestión y creación de insumos para el negocio.
            </Typography>
            <Copyright/>
        </Box>
    );
}

export default Footer;