import Settings from '../settings/ConfigServer';
import storageUtils from "../helpers/tokenUtils";

const GET_INTERLOCUTORS_API_URL = `${Settings.urlLocal}/master/interlocutors`;
const GET_DRIVERS_API_URL = `${Settings.urlLocal}/master/interlocutors/drivers`;
const GET_EMPLOYEES_API_URL = `${Settings.urlLocal}/master/interlocutors/employees`;
const GET_CUSTOMERS_API_URL = `${Settings.urlLocal}/master/interlocutors/customers`;
const GET_DISPATCHERS_API_URL = `${Settings.urlLocal}/master/interlocutors/dispatchers`;
const GET_CONTRACTORS_API_URL = `${Settings.urlLocal}/contractors`;
const CREATE_INTERLOCUTOR_API_URL = `${Settings.urlLocal}/master/interlocutors/create`;
const UPDATE_INTERLOCUTOR_API_URL = `${Settings.urlLocal}/master/interlocutors/update`;
const REMOVE_INTERLOCUTOR_API_URL = `${Settings.urlLocal}/master/interlocutors/delete`;

const InterlocutorService = {}

InterlocutorService.getInterlocutors = async () => {
    const response = await fetch(GET_INTERLOCUTORS_API_URL, {
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    });
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response;
}

InterlocutorService.createInterlocutor = async interlocutor => {
    const response = await fetch(CREATE_INTERLOCUTOR_API_URL, {
        method: 'POST',
        body: JSON.stringify(interlocutor),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    })

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;
}

InterlocutorService.updateInterlocutor = async interlocutor => {
    const response = await fetch(`${UPDATE_INTERLOCUTOR_API_URL}/${interlocutor.id}`, {
        method: 'PUT',
        body: JSON.stringify(interlocutor),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    })

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;
}

InterlocutorService.removeInterlocutor = async interlocutorId => {

    const response = await fetch(`${REMOVE_INTERLOCUTOR_API_URL}/${interlocutorId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    });

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;

}

InterlocutorService.getDrivers = async () => {
    const response = await fetch(GET_DRIVERS_API_URL, {
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    });
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response;
}

InterlocutorService.getEmployees = async () => {
    const response = await fetch(GET_EMPLOYEES_API_URL, {
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    });
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response;
}

InterlocutorService.getCustomers = async () => {
    const response = await fetch(GET_CUSTOMERS_API_URL, {
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`
        }
    });
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response;
}

InterlocutorService.getDispatchers = async () => {
    const response = await fetch(GET_DISPATCHERS_API_URL, {
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`
        }
    });
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response;
}

InterlocutorService.getContractors = async () => {
    const response = await fetch(GET_CONTRACTORS_API_URL, {
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`
        }
    });
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response;
}

export default InterlocutorService;