import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import WeekService from "../../../services/week.service";
import {convertDate, convertEndDateWithTime,convertStartDateWithTime} from "../../../helpers/utils";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    paddingBottom: '10px'
};

const inputStyle = {
    marginBottom: '15px'
}

const WeekModal = props => {

    const isCreateModal = props.modalType === 'CREATE';
    const [week, setWeek] = useState({...props.week})

    const MODAL_TITLE = isCreateModal ? 'Crear Semana' : 'Editar Semana'
    const MODAL_CONFIRM_BUTTON = isCreateModal ? 'Crear' : 'Guardar'

    const inputChangeHandler = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setWeek(week => {
            return {
                ...week,
                [name]: value
            }
        })
    }

    const submitWeekHandler = async e => {
        e.preventDefault();
        const newStartDate = convertStartDateWithTime(week.startDate)
        const newEndDate = convertEndDateWithTime(week.endDate)
        let newWeek = {
            ...week, startDate:newStartDate, endDate: newEndDate
        }
        try {
            if(isCreateModal) {
                await WeekService.createWeek(newWeek, props.weeksSize);
            } else {
                await WeekService.updateWeek(newWeek);
            }

            const successMessage = isCreateModal ? 'Se ha creado correctamente' : 'Se ha actualizado correctamente';
            toast.success(successMessage, {delay: 200});
            closeModalHandler();
        } catch (e) {
            console.error(e);
            toast.error('Ocurrió un error, vuelva a intentar', {delay: 200});
        }
    }

    useEffect(() => {
        if(props.open) setWeek({...props.week})
    }, [props.week, props.open]);

    const closeModalHandler = () => props.onClose();

    return (
        <Modal
            open={props.open}
            onClose={closeModalHandler}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{marginBottom: '30px'}}>
                    {MODAL_TITLE}
                </Typography>
                <form onSubmit={submitWeekHandler}>
                    <TextField name="year"
                               label="Año"
                               variant="outlined"
                               size="small"
                               value={week.year}
                               onChange={inputChangeHandler}
                               fullWidth
                               sx={inputStyle}/>
                    <TextField name="weekNumber"
                               label="Número de semana"
                               variant="outlined"
                               size="small"
                               value={week.weekNumber}
                               onChange={inputChangeHandler}
                               fullWidth
                               sx={inputStyle}/>
                    <TextField name="startDate"
                               label="Fecha Inicio"
                               variant="outlined"
                               size="small"
                               type="date"
                               value={isCreateModal ? week.startDate : convertDate(week.startDate)}
                               onChange={inputChangeHandler}
                               fullWidth
                               sx={inputStyle}/>
                    <TextField name="endDate"
                               label="Fecha fin"
                               variant="outlined"
                               size="small"
                               type="date"
                               value={isCreateModal ? week.endDate : convertDate(week.endDate)}
                               onChange={inputChangeHandler}
                               fullWidth
                               sx={inputStyle}/>
                    <Box sx={{float: 'right'}}>
                        <Button variant="outlined" color="error" onClick={closeModalHandler}
                                sx={{marginRight: '10px'}}>Cancelar</Button>
                        <Button variant="contained" type="submit">{MODAL_CONFIRM_BUTTON}</Button>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
}

export default WeekModal;