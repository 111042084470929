import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import StyledTableCell from "../../layouts/table/StyledTableCell";
import StyledTableRow from "../../layouts/table/StyledTableRow";
import {Card, CardContent, CardHeader} from "@mui/material";
import Typography from "@mui/material/Typography";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Button from "@mui/material/Button";
import React, {useCallback, useEffect, useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ReferenceModal from "./ReferenceModal";
import DeleteItemModal from "../DeleteItemModal";
import ReferenceService from "../../../services/reference.service";
import {toast} from "react-toastify";
import {formatPrice} from "../../../helpers/utils";


const initialReference = {
    id: '',
    code: '',
    name: '',
    type: '',
    weight: 0,
    contractorPriceEntresaca: 0,
    contractorPriceTalarasa: 0,
    active: true
}

const ReferenceList = () => {

    const [references, setReferences] = useState([]);
    const [selectedReference, setSelectedReference] = useState(initialReference)
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    //modals
    const [openCreateReferenceModal, setOpenCreateReferenceModal] = useState(false);
    const [openEditReferenceModal, setOpenEditReferenceModal] = useState(false);
    const [openDeleteReferenceModal, setOpenDeleteReferenceModal] = useState(false);

    const fetchReferencesHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null)
        try {
            const referenceList = await ReferenceService.getReferences();
            setReferences(referenceList);

        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false);
    }, []);

    const removeReferenceHandler = async () => {
        try {
            await ReferenceService.removeReference(selectedReference.id);
            toast.success('Se ha eliminado correctamente', {delay: 1000});
            toggleModal('remove', false)
        } catch (e) {
            console.error(e);
            toast.error('There was an error trying to remove refrence', {delay: 1000});
        }
    };

    const openCreateReferenceHandler = () => {
        setSelectedReference(initialReference)
        toggleModal('create', true);
    }

    const openEditReferenceHandler = (reference) => {
        setSelectedReference(reference)
        toggleModal('edit', true);
    }

    const openRemoveReferenceHandler = (reference) => {
        setSelectedReference(reference)
        toggleModal('remove', true);
    }

    const toggleModal = (modalType, open) => {
        switch (modalType) {
            case 'create':
                setOpenCreateReferenceModal(open);
                break;
            case 'edit':
                setOpenEditReferenceModal(open);
                break;
            case 'remove':
                setOpenDeleteReferenceModal(open);
                break;
            default:
                console.error(`Modal type ${modalType} is not valid`);
        }

    }

    useEffect(() => {
        if (!openCreateReferenceModal && !openDeleteReferenceModal && !openEditReferenceModal) {
            void fetchReferencesHandler();
        }

    }, [fetchReferencesHandler, openCreateReferenceModal, openDeleteReferenceModal, openEditReferenceModal])


    return (
        <>
            <Card
                sx={{width: {sx:'90%', sm: '90%', md: '100%'}}}
            >
                <CardHeader
                sx={{width: {sx:'90%', sm: '90%', md: '100%'}}}
                    title={
                        <Typography variant='h4'>
                            Referencias
                        </Typography>
                    }
                    action={
                        <Button variant="outlined" startIcon={<ControlPointIcon/>}
                                onClick={openCreateReferenceHandler}>
                            Añadir nuevo
                        </Button>
                    }
                >
                </CardHeader>
                <CardContent>
                    {isLoading && references.length <= 0 && <p>Cargando referencias...</p>}
                    {!isLoading && !error && references.length === 0 && <p>No se han creado referencias.</p>}
                    {error && <p> {error} </p>}
                    {references.length > 0 &&
                        <TableContainer component={Paper} sx={{maxWidth: '100%'}}>
                            <Table sx={{minWidth: 380}}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Código</StyledTableCell>
                                        <StyledTableCell align="right">Nombre</StyledTableCell>
                                        <StyledTableCell align="right">Tipo</StyledTableCell>
                                        <StyledTableCell align="right">Peso</StyledTableCell>
                                        <StyledTableCell align="right">Precio Entresaca</StyledTableCell>
                                        <StyledTableCell align="right">Precio Talarasa</StyledTableCell>
                                        <StyledTableCell align="right">Estado</StyledTableCell>
                                        <StyledTableCell align="right"/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {references.map((reference) => (
                                        <StyledTableRow
                                            key={reference.id}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {reference.code}
                                            </TableCell>
                                            <TableCell align="right">{reference.name}</TableCell>
                                            <TableCell align="right">{reference.type}</TableCell>
                                            <TableCell align="right">{reference.weight}</TableCell>
                                            <TableCell align="right">{formatPrice(reference.contractorPriceEntresaca)}</TableCell>
                                            <TableCell align="right">{formatPrice(reference.contractorPriceTalarasa)}</TableCell>
                                            <TableCell
                                                align="right">{reference.active ? 'Activo' : 'Inactivo'}</TableCell>
                                            <TableCell align="right">
                                                <Button onClick={() => openEditReferenceHandler(reference)}>
                                                    <EditIcon/>
                                                </Button>
                                                <Button onClick={() => openRemoveReferenceHandler(reference)}>
                                                    <DeleteIcon color={'warning'}/>
                                                </Button>
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                </CardContent>
            </Card>

            {/*modals*/}
            <ReferenceModal open={openCreateReferenceModal}
                            modalType="CREATE"
                            reference={selectedReference}
                            onClose={() => toggleModal('create', false)}
                            referencesSize={references.length}/>
            <ReferenceModal open={openEditReferenceModal}
                            modalType="UPDATE"
                            reference={selectedReference}
                            onClose={() => toggleModal('edit', false)}
                            referencesSize={references.length}/>
            <DeleteItemModal open={openDeleteReferenceModal}
                             onClose={() => toggleModal('remove', false)}
                             onRemove={removeReferenceHandler}
                             itemName={selectedReference.name}
                             item={"referencia"}/>
        </>

    )
}

export default ReferenceList;