import React from 'react'
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import StyledTableCell from "../../layouts/table/StyledTableCell";
import StyledTableRow from "../../layouts/table/StyledTableRow";
import Paper from "@mui/material/Paper";
import { Card, CardContent, CardHeader, Box, Typography } from "@mui/material"
import {convertDate2} from '../../../helpers/utils'

//Services
import SettleService from '../../../services/settle.service';

const SettlesFind = () => {
  //states
  const [settles, setSettles] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  //functions
  const fetchSettlesHandler = React.useCallback(async () => {
    setIsLoading(true);
    setError(null)
    try {
      const settlesList = await SettleService.getSettles()
      console.log(settlesList)
      setSettles(settlesList);

    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  React.useEffect(() => {
    void fetchSettlesHandler()
  }, [fetchSettlesHandler])

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant='h5'>
            Liquidaciones | Buscar Liquidación
          </Typography>
        }
      />
      <Box sx={{ width: '97%', borderBottom: '1px solid #DEDEDE', margin: '0 auto' }} />
      <CardContent>
        {isLoading && <Typography>Cargando datos</Typography>}
        <Box>
          {settles.length === 0 && <Typography>No hay liquidaciones para mostrar</Typography>}
          {settles.length > 0 &&
            <TableContainer component={Paper} sx={{ maxWidth: '100%' }}>
              <Table sx={{ minWidth: 600 }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Número Liquidación</StyledTableCell>
                    <StyledTableCell align="right">Estado</StyledTableCell>
                    <StyledTableCell align="right">Usuario</StyledTableCell>
                    <StyledTableCell align="right">Fecha de creación</StyledTableCell>
                    {/* <StyledTableCell align="right" /> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {settles.map((settle) => (
                    <StyledTableRow
                      key={settle.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {settle.settleNumber}
                      </TableCell>
                      <TableCell align="right">{settle.state}</TableCell>
                      <TableCell align="right">{settle.userId}</TableCell>
                      <TableCell align="right">{convertDate2(settle.createdAt)}</TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>}
        </Box>
        <Box>
          {error && <p> {error} </p>}
        </Box>
      </CardContent>
    </Card>
  )
}

export default SettlesFind