import React from 'react'
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {Link} from "react-router-dom";

//import icons
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import HandymanIcon from '@mui/icons-material/Handyman';
import PinIcon from '@mui/icons-material/Pin';


const MastersView = () => {
    return (
        <>
            <Box sx={{display: "flex"}}>
                <Container component="main" sx={{height: '100%'}} maxWidth="lg">
                    <CssBaseline/>
                    <Box
                        sx={{
                            marginTop: 12,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Grid container sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Grid item sm={12} md={4} lg={4}
                                  sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center'

                                  }}
                            >

                                <Link to={'/masters/interlocutor-type'} sx={{m: 3}} underline="none">
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            p: 2,
                                        }}
                                    >
                                        <ManageAccountsIcon sx={{width: 120, height: 120, color: 'green'}}/>
                                        <Typography variant='h5' sx={{textAlign: 'center', color: 'green'}}>
                                            Tipos de interlocutor
                                        </Typography>
                                    </Box>
                                </Link>
                            </Grid>
                            <Grid item sm={12} md={4} lg={4}
                                  sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center'

                                  }}
                            >
                                <Link to={'/masters/interlocutor'} sx={{m: 3, textDecoration: 'none'}}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            p: 2,
                                        }}
                                    >
                                        <AccessibilityNewIcon sx={{width: 120, height: 120, color: 'green'}}/>
                                        <Typography variant='h5' sx={{textAlign: 'center', color: 'green'}}>
                                            Interlocutores
                                        </Typography>
                                    </Box>
                                </Link>
                            </Grid>
                            <Grid item sm={12} md={4} lg={4}
                                  sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center'

                                  }}
                            >
                                <Link component={Link} to={'/masters/prices'} sx={{m: 3}}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            p: 2,
                                        }}
                                    >
                                        <MonetizationOnOutlinedIcon sx={{width: 120, height: 120, color: 'green'}}/>
                                        <Typography variant='h5' sx={{textAlign: 'center', color: 'green'}}>
                                            Precios por cliente
                                        </Typography>
                                    </Box>
                                </Link>
                            </Grid>
                            <Grid item sm={12} md={4} lg={4}
                                  sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center'

                                  }}
                            >
                                <Link to={'/masters/zones'} sx={{m: 3}}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            p: 2,
                                        }}
                                    >
                                        <AddLocationAltIcon sx={{width: 120, height: 120, color: 'green'}}/>
                                        <Typography variant='h5' sx={{textAlign: 'center', color: 'green'}}>
                                            Zonas
                                        </Typography>
                                    </Box>
                                </Link>
                            </Grid>


                            <Grid item sm={12} md={4} lg={4}
                                  sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center'

                                  }}
                            >
                                <Link component={Link} to={'/masters/week'} sx={{m: 3}}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            p: 2,
                                        }}
                                    >
                                        <CalendarMonthOutlinedIcon sx={{width: 120, height: 120, color: 'green'}}/>
                                        <Typography variant='h5' sx={{textAlign: 'center', color: 'green'}}>
                                            Semanas
                                        </Typography>
                                    </Box>
                                </Link>
                            </Grid>
                            <Grid item sm={12} md={4} lg={4}
                                  sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center'

                                  }}
                            >
                                <Link component={Link} to={'/masters/const'} sx={{m: 3}}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            p: 2,
                                        }}
                                    >
                                        <HandymanIcon sx={{width: 120, height: 120, color: 'green'}}/>
                                        <Typography variant='h5' sx={{textAlign: 'center', color: 'green'}}>
                                            Constantes
                                        </Typography>
                                    </Box>
                                </Link>
                            </Grid>
                            <Grid item sm={12} md={4} lg={4}
                                  sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center'

                                  }}
                            >
                                <Link to={'/masters/vehicle'} sx={{m: 3}}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            p: 2,
                                        }}
                                    >
                                        <LocalShippingIcon sx={{width: 120, height: 120, color: 'green'}}/>
                                        <Typography variant='h5' sx={{textAlign: 'center', color: 'green'}}>
                                            Vehiculos
                                        </Typography>
                                    </Box>
                                </Link>
                            </Grid>
                            <Grid item sm={12} md={4} lg={4}
                                  sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center'

                                  }}
                            >
                                <Link to={'/masters/consecutive'} sx={{m: 3}}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            p: 2,
                                        }}
                                    >
                                        <PinIcon sx={{width: 120, height: 120, color: 'green'}}/>
                                        <Typography variant='h5' sx={{textAlign: 'center', color: 'green'}}>
                                            Consecutivos
                                        </Typography>
                                    </Box>
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default MastersView