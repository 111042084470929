import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import {useCallback, useEffect, useState} from "react";
import {toast} from "react-toastify";
import VehicleService from "../../../services/vehicle.service";
import interlocutorService from "../../../services/interlocutor.service";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    paddingBottom: '10px'
};

const inputStyle = {
    marginBottom: '15px'
}

const vehicleTypes = ['Camion', 'Camioneta'];

const VehicleModal = props => {

    const isCreateModal = props.modalType === 'CREATE';
    const [vehicle, setVehicle] = useState({...props.vehicle});
    const [drivers, setDrivers] = useState([]);

    const MODAL_TITLE = isCreateModal ? 'Crear Vehiculo' : 'Editar Vehiculo'
    const MODAL_CONFIRM_BUTTON = isCreateModal ? 'Crear' : 'Guardar'

    const inputChangeHandler = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setVehicle(vehicle => {
            return {
                ...vehicle,
                [name]: value
            }
        })
    }

    const submitVehicleHandler = async e => {
        e.preventDefault();
        try {
            if (isCreateModal) {
                await VehicleService.createVehicle(vehicle);
            } else {
                await VehicleService.updateVehicle(vehicle);
            }

            const successMessage = isCreateModal ? 'Se ha creado correctamente' : 'Se ha actualizado correctamente';
            toast.success(successMessage, {delay: 200});
            closeModalHandler();
        } catch (e) {
            console.error(e);
            toast.error('Ocurrió un error, vuelva a intentar', {delay: 200});
        }
    }

    const fetchDriversHandler = useCallback(async () => {
        try {
            const driverList = await interlocutorService.getDrivers();
            setDrivers(driverList);
        } catch (error) {
            console.error(error.message);
        }
    }, []);

    useEffect(() => {
        if(props.open) setVehicle({...props.vehicle})
    }, [props.vehicle, props.open]);

    useEffect(() => {
        void fetchDriversHandler();
    }, [fetchDriversHandler])

    const closeModalHandler = () => props.onClose();

    return (
        <Modal
            open={props.open}
            onClose={closeModalHandler}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{marginBottom: '30px'}}>
                    {MODAL_TITLE}
                </Typography>
                <form onSubmit={submitVehicleHandler}>
                    <TextField name="plate" label="Placa"
                               variant="outlined"
                               size="small"
                               value={vehicle.plate}
                               onChange={inputChangeHandler}
                               fullWidth
                               sx={inputStyle}/>
                    <TextField name="capacity" label="Capacidad"
                               variant="outlined"
                               size="small"
                               type="number"
                               value={vehicle.capacity}
                               onChange={inputChangeHandler}
                               fullWidth
                               sx={inputStyle}/>
                    <TextField name="description" label="Descripción"
                               variant="outlined"
                               size="small"
                               value={vehicle.description}
                               onChange={inputChangeHandler}
                               fullWidth
                               sx={inputStyle}/>
                    <TextField
                        name="type"
                        select
                        label="Tipo de vehiculo"
                        value={vehicle.type}
                        onChange={inputChangeHandler}
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={inputStyle}
                    >
                        {vehicleTypes.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        name="driverId"
                        select
                        label="Transportadora/Conductor"
                        onChange={inputChangeHandler}
                        value={vehicle.driverId}
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={inputStyle}
                    >
                        {drivers.map((driver) => (
                            <MenuItem key={driver.id} value={driver.id}>
                                {`${driver.identificationType}: ${driver.identification} / ${driver.name} (${driver.interlocutorType?.type})`}
                            </MenuItem>
                        ))}
                    </TextField>
                    {
                        !isCreateModal &&
                        <TextField name="active"
                                   select
                                   label="Estado"
                                   variant="outlined"
                                   size="small"
                                   fullWidth
                                   value={vehicle.active ? 1 : 0}
                                   onChange={inputChangeHandler}
                                   sx={inputStyle}>
                            <MenuItem key={1} value={1}>Activo</MenuItem>
                            <MenuItem key={0} value={0}>Inactivo</MenuItem>
                        </TextField>
                    }
                    <Box sx={{float: 'right'}}>
                        <Button variant="outlined" color="error" onClick={closeModalHandler}
                                sx={{marginRight: '10px'}}>Cancelar</Button>
                        <Button variant="contained" type="submit">{MODAL_CONFIRM_BUTTON}</Button>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
}

export default VehicleModal;