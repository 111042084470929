import Settings from '../settings/ConfigServer';
import storageUtils from "../helpers/tokenUtils";

const GET_ZONES_API_URL = `${Settings.urlLocal}/master/zones`;
const GET_ACTIVE_ZONES_API_URL = `${Settings.urlLocal}/master/active-zones`;
const CREATE_ZONE_API_URL = `${Settings.urlLocal}/master/zones/create`;
const UPDATE_ZONE_API_URL = `${Settings.urlLocal}/master/zones/update`;
const REMOVE_ZONE_API_URL = `${Settings.urlLocal}/master/zones/delete`;

const ZoneService = {}

ZoneService.getZones = async () => {
    const response = await fetch(GET_ZONES_API_URL, {
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    });
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response.map(zone => {
        return zone
    });
}

ZoneService.getActiveZones = async () => {
    const response = await fetch(GET_ACTIVE_ZONES_API_URL, {
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`
        }
    });
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response.map(zone => {
        return zone
    });
}

ZoneService.createZone = async (zone) => {

    const response = await fetch(CREATE_ZONE_API_URL, {
        method: 'POST',
        body: JSON.stringify(zone),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    })

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;
}

ZoneService.updateZone = async zone => {

    const response = await fetch(`${UPDATE_ZONE_API_URL}/${zone.id}`, {
        method: 'PUT',
        body: JSON.stringify(zone),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    })

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;
}

ZoneService.removeZone = async zoneId => {

    const response = await fetch(`${REMOVE_ZONE_API_URL}/${zoneId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    });

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;

}

export default ZoneService;