import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {useCallback, useEffect, useMemo} from "react";
import {getWeekNumberCurrent} from "../../../helpers/utils";
import {convertDate} from "../../../helpers/utils";

//Services
import WeekService from "../../../services/week.service"
import InvoiceService from '../../../services/invoice.service';
// import {toast} from "react-toastify";
// import ConsecutiveService from "../../../services/consecutive.service";

const Dashboard = () => {
    const [invoicesWeek, setInvoicesWeek] = React.useState([]);
    const [invoicesDay, setInvoicesDay] = React.useState([]);
    const [invoicesMonth, setInvoicesMonth] = React.useState([]);

    //Date functions
    let currentDate = new Date();
    let tomorrowDate = new Date(currentDate);
        tomorrowDate.setDate(currentDate.getDate()+1);
        tomorrowDate.toLocaleDateString();
   
    let firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    let lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);


    const fetchInvoicesWeekHandler = useCallback(async () => {
        let weekSelected = {
            week: getWeekNumberCurrent(currentDate),
            year: currentDate.getFullYear()
        }
        console.log(weekSelected)
        try {
            const responseWeek = await WeekService.getWeekByYear(weekSelected)
            console.log(responseWeek)
            const dateFrom = responseWeek[0].dateFromRes
            const dateTo = responseWeek[0].dateToRes
            console.log(dateFrom,dateTo)

            const invoiceList = await InvoiceService.getInvoicesByDateAll(dateFrom, dateTo)
            setInvoicesWeek(invoiceList)
        } catch (error) {}
    }, []);

    const fetchInvoicesDayHandler = useCallback(async () => {
        try {
            const dateFrom = convertDate(currentDate);
            const dateTo = convertDate(tomorrowDate);
            console.log(dateFrom,dateTo)

            const invoiceList = await InvoiceService.getInvoicesByDate(dateFrom, dateTo)
            setInvoicesDay(invoiceList)
        } catch (error) {}
    }, []);

    const fetchInvoicesMonthHandler = useCallback(async () => {
        try {
            const dateFrom = convertDate(firstDay);
            const dateTo = convertDate(lastDay);
            console.log(dateFrom,dateTo)

            const invoiceList = await InvoiceService.getInvoicesByDate(dateFrom, dateTo)
            setInvoicesMonth(invoiceList)
        } catch (error) {}
    }, []);


    useEffect(() => {
            void fetchInvoicesWeekHandler();
            // void fetchInvoicesDayHandler();
            // void fetchInvoicesMonthHandler();

    }, [fetchInvoicesWeekHandler])

    return (
        <Box sx={{display: "flex"}}>
            <Container sx={{py: 6, height: '50vw'}} maxWidth="md">
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: '100px'
                }}>
                        <Box sx={{width: '30%'}} >
                            <Box sx={{border: '2px solid green', borderRadius: '5px'}}>
                                <Typography component="h5"
                                            sx={{
                                                color: 'green',
                                                fontSize: '25px',
                                                textAlign: 'center'
                                            }}>
                                    Remisiones semana
                                </Typography>
                            </Box>
                            <Box sx={{border: '2px solid green', borderRadius: '5px', marginTop: '15px'}}>
                                <Typography component="h5"
                                            sx={{
                                                color: 'green',
                                                fontSize: '100px',
                                                textAlign: 'center'
                                            }}>
                                    {invoicesWeek.length}
                                </Typography>
                            </Box>
                        </Box>
                    <Box sx={{width: '30%'}} >
                        <Box sx={{border: '2px solid green', borderRadius: '5px'}}>
                            <Typography component="h5"
                                        sx={{
                                            color: 'green',
                                            fontSize: '25px',
                                            textAlign: 'center'
                                        }}>
                                Remisiones hoy
                            </Typography>
                        </Box>
                        <Box sx={{border: '2px solid green', borderRadius: '5px', marginTop: '15px'}}>
                            <Typography component="h5"
                                        sx={{
                                            color: 'green',
                                            fontSize: '100px',
                                            textAlign: 'center'
                                        }}>
                                {invoicesDay.length}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{width: '30%'}} >
                        <Box sx={{border: '2px solid green', borderRadius: '5px'}}>
                            <Typography component="h5"
                                        sx={{
                                            color: 'green',
                                            fontSize: '25px',
                                            textAlign: 'center'
                                        }}>
                                Remisiones mes
                            </Typography>
                        </Box>
                        <Box sx={{border: '2px solid green', borderRadius: '5px', marginTop: '15px'}}>
                            <Typography component="h5"
                                        sx={{
                                            color: 'green',
                                            fontSize: '100px',
                                            textAlign: 'center'
                                        }}>
                                {invoicesMonth.length}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default Dashboard