import {styled} from "@mui/material/styles";
import {tableCellClasses} from "@mui/material";
import TableCell from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontStyle: 'bold'
    },
}));

export default StyledTableCell;