import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import StyledTableCell from "../../layouts/table/StyledTableCell";
import StyledTableRow from "../../layouts/table/StyledTableRow";
import {Card, CardContent, CardHeader} from "@mui/material";
import Typography from "@mui/material/Typography";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Button from "@mui/material/Button";
import React, {useCallback, useEffect, useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from '@mui/icons-material/Person';
import DeleteItemModal from "../DeleteItemModal";
import {toast} from "react-toastify";
import UserService from "../../../services/user.service";
import UserModal from "./UserModal";
import UserViewModal from "./UserViewModal";
import {capitalizeFirstLetter, convertDate} from "../../../helpers/utils";


const initialUser = {
    id: '',
    username: '',
    password: '',
    roleId: '',
    employeeId: '',
    active: true
}

const UserList = () => {

    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(initialUser)
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    //modals
    const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
    const [openEditUserModal, setOpenEditUserModal] = useState(false);
    const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
    const [openViewUserModal, setOpenViewUserModal] = useState(false);

    const fetchUsersHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null)
        try {
            const usersList = await UserService.getUsers();
            setUsers(usersList);

        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false);
    }, []);

    const removeUserHandler = async () => {
        try {
            await UserService.removeUser(selectedUser.id);
            toast.success('Se ha eliminado correctamente');
            toggleModal('remove', false)
        } catch (e) {
            console.error(e);
            toast.error('There was an error trying to remove this user');
        }
    };

    const openCreateUserHandler = () => {
        setSelectedUser(initialUser)
        toggleModal('create', true);
    }

    const openEditUserHandler = (user) => {
        setSelectedUser(selectedUser => user)
        toggleModal('edit', true);
    }

    const openRemoveUserHandler = (user) => {
        setSelectedUser(user)
        toggleModal('remove', true);
    }

    const openViewUserHandler = (user) => {
        setSelectedUser(selectedUser => user)
        toggleModal('view', true);
    }

    const toggleModal = (modalType, open) => {
        switch (modalType) {
            case 'create':
                setOpenCreateUserModal(open);
                break;
            case 'edit':
                setOpenEditUserModal(open);
                break;
            case 'remove':
                setOpenDeleteUserModal(open);
                break;
            case 'view':
                setOpenViewUserModal(open);
                break;
            default:
                console.error(`Modal type ${modalType} is not valid`);
        }

    }

    useEffect(() => {
        if (!openCreateUserModal && !openDeleteUserModal && !openEditUserModal) {
            void fetchUsersHandler();
        }

    }, [fetchUsersHandler, openCreateUserModal, openDeleteUserModal, openEditUserModal])


    return (
        <>
            <Card
                sx={{width: {sx:'90%', sm:'90%', md: '100%', lg: '100%'}}}
            >
                <CardHeader
                    sx={{width: {sx:'90%', sm:'90%', md: '100%', lg: '100%'}}}
                    title={
                        <Typography variant='h4'>
                            Usuarios
                        </Typography>
                    }
                    action={
                        <Button variant="outlined" startIcon={<ControlPointIcon/>}
                                onClick={openCreateUserHandler}>
                            Añadir nuevo
                        </Button>
                    }
                >
                </CardHeader>
                <CardContent>
                    {isLoading && users.length <= 0 && <p>Cargando usuarios...</p>}
                    {!isLoading && !error && users.length === 0 && <p>No se han creado usuarios.</p>}
                    {error && <p> {error} </p>}
                    {users.length > 0 &&
                        <TableContainer component={Paper} sx={{maxWidth: '100%'}}>
                            <Table sx={{minWidth: 390}}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Nombre de usuario</StyledTableCell>
                                        <StyledTableCell align="right">Contraseña</StyledTableCell>
                                        <StyledTableCell align="right">Activo</StyledTableCell>
                                        <StyledTableCell align="right">Rol</StyledTableCell>
                                        <StyledTableCell align="right">Creado</StyledTableCell>
                                        <StyledTableCell align="right">Ultima sesion</StyledTableCell>
                                        <StyledTableCell align="right"/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map((user) => (
                                        <StyledTableRow
                                            key={user.id}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {user.username}
                                            </TableCell>
                                            <TableCell align="right">***********</TableCell>
                                            <TableCell align="right">{user.active ? 'Activo': 'Inactivo'}</TableCell>
                                            <TableCell align="right">{capitalizeFirstLetter(user.role?.name)}</TableCell>
                                            <TableCell align="right">{convertDate(user.createdAt)}</TableCell>
                                            <TableCell align="right">{convertDate(user.lastSession)}</TableCell>
                                            <TableCell align="right">
                                                <Button onClick={() => openViewUserHandler(user)}>
                                                    <PersonIcon/>
                                                </Button>
                                                <Button onClick={() => openEditUserHandler(user)}>
                                                    <EditIcon/>
                                                </Button>
                                                <Button onClick={() => openRemoveUserHandler(user)}>
                                                    <DeleteIcon color={'warning'}/>
                                                </Button>
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                </CardContent>
            </Card>

            {/*modals*/}
            <UserViewModal open={openViewUserModal}
                       user={selectedUser}
                       onClose={() => toggleModal('view', false)}/>
            <UserModal open={openCreateUserModal}
                            modalType="CREATE"
                            user={selectedUser}
                            onClose={() => toggleModal('create', false)}/>
            <UserModal open={openEditUserModal}
                            modalType="UPDATE"
                            user={selectedUser}
                            onClose={() => toggleModal('edit', false)}/>
            <DeleteItemModal open={openDeleteUserModal}
                             onClose={() => toggleModal('remove', false)}
                             onRemove={removeUserHandler}
                             itemName={selectedUser.username}
                             item={"usuario"}/>
        </>

    )
}

export default UserList;