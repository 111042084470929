import Settings from "../settings/ConfigServer";
import tokenUtils from "../helpers/tokenUtils";

const GET_ROLES_API_URL = `${Settings.urlLocal}/roles`;

const RoleService = {}

RoleService.getRoles = async () => {
    const response = await fetch(GET_ROLES_API_URL, {
        headers: {
            'Authorization': `Bearer ${tokenUtils.getToken()}`
        }
    })
    if (!response.ok) {
        return []
    }
    const data = await response.json();
    return data.response;
}

export default RoleService;