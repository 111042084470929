import Settings from '../settings/ConfigServer';
import storageUtils from "../helpers/tokenUtils";

const GET_VEHICLES_API_URL = `${Settings.urlLocal}/master/vehicles`;
const GET_ACTIVE_VEHICLES_API_URL = `${Settings.urlLocal}/activeVehicles`;
const GET_VEHICLES_BY_DRIVER_API_URL = `${Settings.urlLocal}/vehiclesByDriver`;
const CREATE_VEHICLE_API_URL = `${Settings.urlLocal}/master/vehicles/create`;
const UPDATE_VEHICLE_API_URL = `${Settings.urlLocal}/master/vehicles/update`;
const REMOVE_VEHICLE_API_URL = `${Settings.urlLocal}/master/vehicles/delete`;

const VehicleService = {}

VehicleService.getVehicles = async () => {
    const response = await fetch(GET_VEHICLES_API_URL, {
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    });
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response;
}

VehicleService.getActiveVehicles = async () => {
    const response = await fetch(GET_ACTIVE_VEHICLES_API_URL, {
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`
        }
    });
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response;
}

VehicleService.getVehiclesByDriver= async driverId => {
    const response = await fetch(`${GET_VEHICLES_BY_DRIVER_API_URL}/${driverId}`, {
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`
        }
    });
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response;
}

VehicleService.createVehicle = async vehicle => {
    const response = await fetch(CREATE_VEHICLE_API_URL, {
        method: 'POST',
        body: JSON.stringify(vehicle),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    })

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;
}

VehicleService.updateVehicle = async vehicle => {
    const response = await fetch(`${UPDATE_VEHICLE_API_URL}/${vehicle.id}`, {
        method: 'PUT',
        body: JSON.stringify(vehicle),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    })

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;
}

VehicleService.removeVehicle = async vehicleId => {

    const response = await fetch(`${REMOVE_VEHICLE_API_URL}/${vehicleId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    });

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;

}

export default VehicleService;