import React from 'react'

//import MUI componets
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

const Notfound = () => {
    return (
        <Container component="main" maxWidth="xs" sx={{overflowX:'hidden'}}>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography variant={'h2'}>
                    404
                </Typography>
                <Typography>
                    La página a la que intenta acceder no existe
                </Typography>
            </Box>
        </Container>
    )
}

export default Notfound