import {useEffect, useState} from "react";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";
import ConsecutiveService from "../../../services/consecutive.service";
import {toast} from "react-toastify";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    paddingBottom: '10px',
};

const ConsecutiveEditModal = props => {
    const [consecutive, setConsecutive] = useState(props.consecutive);
    const MODAL_TITLE = 'Editar consecutivo';

    const numberInputChangeHandler = e => {
        const number = e.target.value
        if (number > 0) {
            setConsecutive(consecutive => {
                return {
                    ...consecutive,
                    number: e.target.value
                }
            })
        }
    }

    const closeModalHandler = () => props.onClose();

    const submitConsecutiveHandler = async e => {
        e.preventDefault();
        try {
            await ConsecutiveService.updateConsecutive(consecutive);
            toast.success('Se ha actualizado correctamente', {delay: 200});
            closeModalHandler();
        } catch (e) {
            console.error(e);
            toast.error(e.message, {delay: 200});
        }
    }

    useEffect(() => {
        setConsecutive(props.consecutive)
    }, [props.consecutive])

    return (
        <Modal open={props.open}
               onClose={closeModalHandler}
               aria-labelledby="modal-modal-title"
               aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{marginBottom: '30px'}}>
                    {`${MODAL_TITLE} ${consecutive.type}`}
                </Typography>
                <Box component="form" onSubmit={submitConsecutiveHandler}>
                    <TextField
                        name="number"
                        type="number"
                        label="Nuevo consecutivo"
                        value={consecutive.number}
                        onChange={numberInputChangeHandler}
                        variant="outlined"
                        size="small"
                        margin="dense"
                        fullWidth
                        InputProps={{
                            inputProps: {
                                min: 0
                            }
                        }}
                    />
                    <Box sx={{float: 'right', marginTop: '10px'}}>
                        <Button variant="outlined" color="error" onClick={closeModalHandler}
                                sx={{marginRight: '10px'}}>Cancelar</Button>
                        <Button variant="contained" type="submit">Guardar</Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )

}

export default ConsecutiveEditModal;