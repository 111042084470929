import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    paddingBottom: '10px',
    maxWidth: '90%',
    maxHeight: '80%',
};

const DeleteItemModal = props => {
    const closeModalHandler = () => props.onClose();

    const removeItem = () => {
        props.onRemove();
    }

    return (
        <Modal
            open={props.open}
            onClose={closeModalHandler}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{marginBottom: '30px'}}>
                    Eliminar {props.item}
                </Typography>
                <Typography component="p">
                    ¿Está seguro de eliminar {props.item}&nbsp;
                    <Typography component="span" fontWeight="bold" sx={{textDecoration: 'underline'}}>
                        {props.itemName}
                    </Typography>
                    &nbsp;?
                </Typography>
                <Box sx={{float: 'right', marginTop: 5}}>
                    <Button variant="outlined" color="error" onClick={closeModalHandler}
                            sx={{marginRight: '10px'}}>Cancelar</Button>
                    <Button variant="contained" onClick={removeItem}>Confirmar</Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default DeleteItemModal;