import {styled} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Box from "@mui/material/Box";
import {Avatar} from "@mui/material";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import {Link} from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import HomeIcon from "@mui/icons-material/Home";
import ListItemText from "@mui/material/ListItemText";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ArticleIcon from "@mui/icons-material/Article";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import LogoutIcon from "@mui/icons-material/Logout";
import useRole from "../../../hooks/useRole";
import Roles from "../../../helpers/roles";
import AssessmentIcon from '@mui/icons-material/Assessment';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
            "& .MuiListItemIcon-root, .MuiIconButton-root": {
                color: "inherit"
            },
            iconColorPrimary: theme.palette.secondary.main,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const MainDrawer = props => {

    const toggleDrawerHandler = () => {
        props.onToggleDrawer();
    }

    const {role} = useRole();

    return (
        <Drawer variant="permanent" open={props.open}>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                <IconButton onClick={toggleDrawerHandler}>
                    <ChevronLeftIcon/>
                </IconButton>
            </Toolbar>
            <Box component={Link} to={'/'} sx={{
                width: "100%",
                height: () => props.open ? 190 : 110,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                transition: "0.3s ease"
            }}>
                <Avatar
                    src='/logo_cabana.png'
                    sx={{
                        width: () => props.open ? 100 : 35,
                        height: () => props.open ? 100 : 35,
                        marginBottom: 1,
                        transition: "0.3s ease",
                    }}
                />
                <Typography color={"white"} sx={{opacity: () => props.open ? 1 : 0,}}> </Typography>
            </Box>
            <List component="nav">
                <ListItemButton component={Link} to={'/'}>
                    <ListItemIcon>
                        <HomeIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Inicio"/>
                </ListItemButton>
                {role === Roles.Admin &&
                <ListItemButton component={Link} to={'/references'}>
                    <ListItemIcon>
                        <BusinessCenterIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Referencias"/>
                </ListItemButton>
                }
                <ListItemButton component={Link} to={'/invoices'}>
                    <ListItemIcon>
                        <ArticleIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Remisiones"/>
                </ListItemButton>
                {role === Roles.Admin &&
                    <>
                        <ListItemButton component={Link} to={'/settles'}>
                            <ListItemIcon>
                                <AssessmentIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Liquidaciones"/>
                        </ListItemButton>
                        <ListItemButton component={Link} to={'/users'}>
                            <ListItemIcon>
                                <PeopleAltIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Usuarios"/>
                        </ListItemButton>
                        <ListItemButton component={Link} to={'/masters'}>
                            <ListItemIcon>
                                <AddHomeWorkIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Maestros"/>
                        </ListItemButton>
                    </>
                }
                <ListItemButton sx={{position: "relative", top: 60}} onClick={props.onLogout}>
                    <ListItemIcon>
                        <LogoutIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Salir"/>
                </ListItemButton>
            </List>
        </Drawer>
    );
}

export default MainDrawer;