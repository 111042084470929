import React, {useCallback, useEffect, useState} from "react";
import ConsecutiveService from "../../../services/consecutive.service";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ConsecutiveEditModal from "./ConsecutiveEditModal";

const ConsecutiveList = () => {
    const [consecutive, setConsecutive] = useState([]);
    const [selectedConsecutive, setSelectedConsecutive] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    //modals
    const [openEditIConsecutiveModal, setOpenEditConsecutiveModal] = useState(false);

    const fetchConsecutiveHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null)
        try {
            const consecutiveList = await ConsecutiveService.getConsecutive();
            setConsecutive(consecutiveList);

        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false);
    }, []);

    const openEditConsecutiveHandler = (consecutive) => {
        setSelectedConsecutive(selectedConsecutive => consecutive)
        openModal(true);
    }
    const openModal = open => {
        setOpenEditConsecutiveModal(open)
    }

    useEffect(() => {
        if(!openEditIConsecutiveModal){
            void fetchConsecutiveHandler();
        }
    }, [fetchConsecutiveHandler, openEditIConsecutiveModal])

    return (
        <>
            <Typography component="h2" sx={{textAlign: 'center', fontSize: '45px', fontWeight: 'bold'}}>
                Consecutivos
            </Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: '100px'
            }}>
                {isLoading && consecutive.length <= 0 && <p>Cargando consecutivos...</p>}
                {!isLoading && !error && consecutive.length === 0 && <p>No se han creado consecutivos.</p>}
                {error && <p> {error} </p>}
                {consecutive.map((consecutiveElement) => (
                    <Box sx={{width: '40%'}} key={consecutiveElement.id}>
                        <Box sx={{border: '2px solid green', borderRadius: '5px'}}>
                            <Typography component="h5"
                                        sx={{
                                            color: 'green',
                                            fontSize: '30px',
                                            textAlign: 'center'
                                        }}>
                                {consecutiveElement.type}
                            </Typography>
                        </Box>
                        <Box sx={{border: '2px solid green', borderRadius: '5px', marginTop: '15px'}}>
                            <Typography component="h5"
                                        sx={{
                                            color: 'green',
                                            fontSize: '120px',
                                            textAlign: 'center'
                                        }}>
                                {consecutiveElement.number}
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Button variant="outlined" color="error"
                                    sx={{marginTop: '10px', width: '45%'}}
                                    onClick={() => openEditConsecutiveHandler(consecutiveElement)}>
                                Cambiar
                            </Button>
                        </Box>
                    </Box>
                ))}
            </Box>

            <ConsecutiveEditModal open={openEditIConsecutiveModal}
                                  onClose={() => openModal(false)}
                                  consecutive={selectedConsecutive}/>
        </>
    )

}

export default ConsecutiveList;