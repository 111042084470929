import React from 'react'
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import StyledTableCell from "../../layouts/table/StyledTableCell";
import StyledTableRow from "../../layouts/table/StyledTableRow";
import Paper from "@mui/material/Paper";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { Card, CardContent, CardHeader, Box, Typography, Button, TextField } from "@mui/material"
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { toast } from "react-toastify";
import { getWeekNumberCurrent } from "../../../helpers/utils"

//Services
import WeekService from "../../../services/week.service"
import InvoiceService from '../../../services/invoice.service'
import SettleService from '../../../services/settle.service'
import ConsecutiveService from '../../../services/consecutive.service';

const SetlesCreate = () => {

  //States
  const [week, setWeek] = React.useState("")
  const [year, setYear] = React.useState(new Date().getFullYear())
  const [weekId, setWeekId] = React.useState("")
  const [settleNumber, setSettleNumber] = React.useState("")
  const [invoices, setInvoices] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState(null)


  const fetchInvoiceNumber = React.useCallback(async () => {
    try {
        const settleNumber = await ConsecutiveService.getConsecutive();
        setSettleNumber(settleNumber[1].number)
    } catch (error) {
        console.error(error.message);
    }
}, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    setError(null)
    setInvoices([])
    if (week !== "" || year === null || year === "") {
      let weekSelected = {
        week: week,
        year: year
      }
      //console.log(weekSelected)
      try {
        const responseWeek = await WeekService.getWeekByYear(weekSelected)
        //console.log(responseWeek)
        if (responseWeek.length === 0) {
          setError('No hay datos para mostrar')
          setIsLoading(false)
        } else {
          setWeekId(responseWeek[0].id)
          const dateFrom = responseWeek[0].dateFromRes
          const dateTo = responseWeek[0].dateToRes
          const invoiceList = await InvoiceService.getInvoicesByDate(dateFrom, dateTo)
          //console.log(invoiceList)
          if (invoiceList.length === 0) {
            setError('No hay datos para mostrar')
            setIsLoading(false)
          } else {
            setInvoices(invoiceList)
            setIsLoading(false)
          }
        }
      } catch (error) {
        setError(error.message)
        setIsLoading(false)
      }
    } else {
      toast.error('Debes seleccionar el año y una semana del año', 3000)
      setIsLoading(false)
    }
  }

  const handleSettleSubmit = async () => {
    setIsLoading(true)
    setError(null)
    let selectedInvoices = invoices
    let selectedWeekId = weekId
    let selectedSettleNumber = settleNumber
    if(selectedInvoices.length === 0){
      setIsLoading(false)
      setError('No se puede liquidar porque no hay Remisiones')
    }else{
      if(selectedWeekId === ""){
        setIsLoading(false)
        setError('No se puede liquidar porque no se ha creado la semana')
      }else{
        try {
          await SettleService.createSettle(selectedInvoices, selectedWeekId, selectedSettleNumber)
          // console.log(responseSettle)
          setInvoices([])
          toast.success('Liquidación efectuada con éxito')
          setIsLoading(false)
        } catch (error) {
          setError(error.message)
          setIsLoading(false)
        }
      }
    }
  }

  React.useEffect(() => {
    void setWeek(getWeekNumberCurrent())
    void fetchInvoiceNumber()
  }, [fetchInvoiceNumber])

  return (
    <>
      <Card>
        <CardHeader
          title={
            <Typography variant='h5'>
              Liquidaciones | Crear liquidación
            </Typography>
          }
        >
        </CardHeader>
        <Box sx={{ width: '97%', borderBottom: '1px solid #DEDEDE', margin: '0 auto' }} />
        <CardContent>
          <Box sx={{ mb: 2 }}>
            <Typography variant='p'>Por favor especifica una semana</Typography>
          </Box>
          <Box>
            <form onSubmit={(e) => handleSubmit(e)}>
              <Box sx={{ my: 2 }}>
                <TextField
                  fullWidth
                  id={'year'}
                  name={'year'}
                  label={'Año'}
                  variant="outlined"
                  value={year}
                  onChange={(e) => { setYear(e.target.value) }}
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  id={'week'}
                  name={'week'}
                  label={'Semana del año'}
                  variant="outlined"
                  value={week}
                  onChange={(e) => { setWeek(e.target.value) }}
                  sx={{ mb: 2 }}
                />
              </Box>
              <Box>
                <Button type='submit' variant="outlined" endIcon={<ManageSearchIcon />}>Consultar</Button>
              </Box>
            </form>

          </Box>
          <Box>
            {isLoading && <Typography>Cargando datos</Typography>}
            {invoices.length > 0 &&
              <TableContainer component={Paper} sx={{ maxWidth: '100%' }}>
                <Table sx={{ minWidth: 600 }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Número de Remisión</StyledTableCell>
                      <StyledTableCell align="right">Cliente</StyledTableCell>
                      <StyledTableCell align="right">Destino</StyledTableCell>
                      <StyledTableCell align="right">Estado</StyledTableCell>
                      {/* <StyledTableCell align="right" /> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoices.map((invoice) => (
                      <StyledTableRow
                        key={invoice.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {invoice.invoiceNumber}
                        </TableCell>
                        <TableCell align="right">{invoice.customer?.name}</TableCell>
                        <TableCell align="right">{invoice.destination}</TableCell>
                        <TableCell align="right">{invoice.state}</TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>}
          </Box>
          <Box>
            {error && <p> {error} </p>}
          </Box>
          <Box
            sx={{
              my:2,
              display:'flex',
              justifyContent: 'center',
              alignItems:'center'
            }}
          >
            {invoices.length > 0 &&
              <LoadingButton
                loading={isLoading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined"
                onClick={() => handleSettleSubmit()}
              >
                Liquidar
              </LoadingButton>
            }
          </Box>
        </CardContent>
      </Card>
    </>
  )
}

export default SetlesCreate