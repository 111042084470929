import Settings from '../settings/ConfigServer';
import tokenUtils from "../helpers/tokenUtils";

const GET_REFERENCES_API_URL = `${Settings.urlLocal}/references`;
const GET_ACTIVE_REFERENCES_API_URL = `${Settings.urlLocal}/active-references`;
const CREATE_REFERENCE_API_URL = `${Settings.urlLocal}/references/create`;
const UPDATE_REFERENCE_API_URL = `${Settings.urlLocal}/references/update`;
const REMOVE_REFERENCE_API_URL = `${Settings.urlLocal}/references/delete`;

const ReferenceService = {}

ReferenceService.getReferences = async () => {
    const response = await fetch(GET_REFERENCES_API_URL, {
        headers: {
            'Authorization': `Bearer ${tokenUtils.getToken()}`
        }
    });
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response.map(reference => {
        return reference
    });
}

ReferenceService.getActiveReferences = async (invoiceId) => {
    const response = await fetch(GET_ACTIVE_REFERENCES_API_URL, {
        headers: {
            'Authorization': `Bearer ${tokenUtils.getToken()}`
        }
    });
    
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response.map(reference => {
        return reference
    });
}

ReferenceService.createReference = async (reference) => {

    const response = await fetch(CREATE_REFERENCE_API_URL, {
        method: 'POST',
        body: JSON.stringify(reference),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokenUtils.getToken()}`
        }
    })

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;
}

ReferenceService.updateReference = async reference => {

    const response = await fetch(`${UPDATE_REFERENCE_API_URL}/${reference.id}`, {
        method: 'PUT',
        body: JSON.stringify(reference),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokenUtils.getToken()}`
        }
    })

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;
}

ReferenceService.removeReference = async referenceId => {

    const response = await fetch(`${REMOVE_REFERENCE_API_URL}/${referenceId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${tokenUtils.getToken()}`
        }
    });

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;

}

export default ReferenceService;