import {styled} from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import {tableCellClasses} from "@mui/material";

const StyledGreenTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export default StyledGreenTableCell;