import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ReferenceService from '../../../services/reference.service'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    paddingBottom: '10px'
};

const inputStyle = {
    marginBottom: '15px'
}

const referenceTypes = ['Rolo', 'Pulpa', 'Pulpa seleccionada', 'Estacon', 'Poste', 'Alfarda']

const ReferenceModal = props => {

    const isCreateModal = props.modalType === 'CREATE';
    const [reference, setReference] = useState({ ...props.reference })

    const MODAL_TITLE = isCreateModal ? 'Crear Referencia' : 'Editar Referencia'
    const MODAL_CONFIRM_BUTTON = isCreateModal ? 'Crear' : 'Guardar'

    const inputChangeHandler = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setReference(reference => {
            return {
                ...reference,
                [name]: value
            }
        })
    }

    const submitReferenceHandler = async e => {
        e.preventDefault();
        try {
            if (isCreateModal) {
                await ReferenceService.createReference(reference, props.referencesSize);
            } else {
                await ReferenceService.updateReference(reference);
            }

            const successMessage = isCreateModal ? 'Se ha creado correctamente' : 'Se ha actualizado correctamente';
            toast.success(successMessage, { delay: 200 });
            closeModalHandler();
        } catch (e) {
            console.error(e);
            toast.error('Ocurrió un error, vuelva a intentar', { delay: 200 });
        }
    }

    useEffect(() => {
        if (props.open) setReference({ ...props.reference })
    }, [props.reference, props.open]);

    const closeModalHandler = () => props.onClose();

    return (
        <Modal
            open={props.open}
            onClose={closeModalHandler}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBottom: '30px' }}>
                    {MODAL_TITLE}
                </Typography>
                <form onSubmit={submitReferenceHandler}>
                    <TextField name="code" label="Código"
                        variant="outlined"
                        size="small"
                        value={reference.code}
                        onChange={inputChangeHandler}
                        fullWidth
                        sx={inputStyle} />
                    <TextField name="name"
                        label="Nombre"
                        variant="outlined"
                        size="small"
                        value={reference.name}
                        onChange={inputChangeHandler}
                        fullWidth
                        sx={inputStyle} />
                    <TextField
                        name="type"
                        select
                        label="Tipo"
                        value={reference.type}
                        onChange={inputChangeHandler}
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={inputStyle}
                    >
                        {referenceTypes.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                    <FormControl fullWidth sx={inputStyle}>
                        <InputLabel htmlFor="weight">Peso</InputLabel>
                        <OutlinedInput
                            id="weight"
                            name="weight"
                            size="small"
                            startAdornment={<InputAdornment position="start">Kg</InputAdornment>}
                            label="Peso"
                            type="number"
                            value={reference.weight}
                            onChange={inputChangeHandler}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={inputStyle}>
                        <InputLabel htmlFor="contractorPriceEntresaca">Precio de contratista Entresaca</InputLabel>
                        <OutlinedInput
                            id="contractorPriceEntresaca"
                            name="contractorPriceEntresaca"
                            size="small"
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            label="Precio de contratista Entresaca"
                            type="number"
                            value={reference.contractorPriceEntresaca}
                            onChange={inputChangeHandler}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={inputStyle}>
                        <InputLabel htmlFor="contractorPriceTalarasa">Precio de contratista Talarasa</InputLabel>
                        <OutlinedInput
                            id="contractorPriceTalarasa"
                            name="contractorPriceTalarasa"
                            size="small"
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            label="Precio de contratista Talarasa"
                            type="number"
                            value={reference.contractorPriceTalarasa}
                            onChange={inputChangeHandler}
                        />
                    </FormControl>
                    {
                        !isCreateModal &&
                        <TextField name="active"
                            select
                            label="Estado"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={reference.active ? 1 : 0}
                            onChange={inputChangeHandler}
                            sx={inputStyle}>
                            <MenuItem key={1} value={1}>Activo</MenuItem>
                            <MenuItem key={0} value={0}>Inactivo</MenuItem>
                        </TextField>
                    }
                    <Box sx={{ float: 'right' }}>
                        <Button variant="outlined" color="error" onClick={closeModalHandler}
                            sx={{ marginRight: '10px' }}>Cancelar</Button>
                        <Button variant="contained" type="submit">{MODAL_CONFIRM_BUTTON}</Button>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
}

export default ReferenceModal;