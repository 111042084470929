import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import StyledTableCell from "../../layouts/table/StyledTableCell";
import StyledTableRow from "../../layouts/table/StyledTableRow";
import {Card, CardContent, CardHeader} from "@mui/material";
import Typography from "@mui/material/Typography";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Button from "@mui/material/Button";
import React, {useCallback, useEffect, useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteItemModal from "../DeleteItemModal";
import {toast} from "react-toastify";
import PriceService from "../../../services/price.service";
import PriceModal from "./PriceModal";
import {formatPrice} from "../../../helpers/utils";


const initialPrice = {
    id: '',
    interlocutorId: '',
    referenceId: '',
    salePrice: '',
    active: true
}

const PriceList = () => {

    const [prices, setPrices] = useState([]);
    const [selectedPrice, setSelectedPrice] = useState(initialPrice)
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    //modals
    const [openCreatePriceModal, setOpenCreatePriceModal] = useState(false);
    const [openEditPriceModal, setOpenEditPriceModal] = useState(false);
    const [openDeletePriceModal, setOpenDeletePriceModal] = useState(false);

    const fetchPricesHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null)
        try {
            const priceList = await PriceService.getPrices();
            setPrices(priceList);

        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false);
    }, []);

    const removePriceHandler = async () => {
        try {
            await PriceService.removePrice(selectedPrice.id);
            toast.success('Se ha eliminado correctamente');
            toggleModal('remove', false)
        } catch (e) {
            console.error(e);
            toast.error('There was an error trying to remove this interlocutor');
        }
    };

    const openCreatePriceHandler = () => {
        setSelectedPrice(initialPrice)
        toggleModal('create', true);
    }

    const openEditPriceHandler = (price) => {
        setSelectedPrice(selectedPrice => price)
        toggleModal('edit', true);
    }

    const openRemovePriceHandler = (price) => {
        setSelectedPrice(price)
        toggleModal('remove', true);
    }

    const toggleModal = (modalType, open) => {
        switch (modalType) {
            case 'create':
                setOpenCreatePriceModal(open);
                break;
            case 'edit':
                setOpenEditPriceModal(open);
                break;
            case 'remove':
                setOpenDeletePriceModal(open);
                break;
            default:
                console.error(`Modal type ${modalType} is not valid`);
        }

    }

    useEffect(() => {
        if (!openCreatePriceModal && !openDeletePriceModal && !openEditPriceModal) {
            void fetchPricesHandler();
        }

    }, [openCreatePriceModal, openDeletePriceModal, openEditPriceModal,
        fetchPricesHandler])


    return (
        <>
            <Card>
                <CardHeader
                    title={
                        <Typography variant='h4'>
                            Precios por cliente
                        </Typography>
                    }
                    action={
                        <Button variant="outlined" startIcon={<ControlPointIcon/>}
                                onClick={openCreatePriceHandler}>
                            Añadir nuevo
                        </Button>
                    }
                >
                </CardHeader>
                <CardContent>
                    {isLoading && prices.length <= 0 && <p>Cargando Precios...</p>}
                    {!isLoading && !error && prices.length === 0 && <p>No se han creado precios.</p>}
                    {error && <p> {error} </p>}
                    {prices.length > 0 &&
                        <TableContainer component={Paper} sx={{maxWidth: '100%'}}>
                            <Table sx={{minWidth: 400}}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Interlocutor</StyledTableCell>
                                        <StyledTableCell align="right">Referencia</StyledTableCell>
                                        <StyledTableCell align="right">Precio cliente</StyledTableCell>
                                        <StyledTableCell align="right">Estado</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {prices.map((price) => (
                                        <StyledTableRow
                                            key={price.id}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell align="right">
                                                {`${price.interlocutor?.name}`}
                                            </TableCell>
                                            <TableCell align="right">
                                                {`${price.reference?.name}`}
                                            </TableCell>
                                            <TableCell align="right">{formatPrice(price.salePrice)}</TableCell>
                                            <TableCell
                                                align="right">{price.active ? 'Activo' : 'Inactivo'}</TableCell>
                                            <TableCell align="right">
                                                <Button onClick={() => openEditPriceHandler(price)}>
                                                    <EditIcon/>
                                                </Button>
                                                <Button onClick={() => openRemovePriceHandler(price)}>
                                                    <DeleteIcon color={'warning'}/>
                                                </Button>
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                </CardContent>
            </Card>

            {/*modals*/}
            <PriceModal open={openCreatePriceModal}
                          modalType="CREATE"
                          price={selectedPrice}
                          onClose={() => toggleModal('create', false)}/>
            <PriceModal open={openEditPriceModal}
                          modalType="UPDATE"
                          price={selectedPrice}
                          onClose={() => toggleModal('edit', false)}/>
            <DeleteItemModal open={openDeletePriceModal}
                             onClose={() => toggleModal('remove', false)}
                             onRemove={removePriceHandler}
                             itemName={selectedPrice.plate}
                             item={"precio"}/>
        </>

    )
}

export default PriceList;