import Settings from '../settings/ConfigServer';
import storageUtils from "../helpers/tokenUtils";

const GET_CONSECUTIVE_API_URL = `${Settings.urlLocal}/master/consecutive`;
const GET_CONSECUTIVE_NUMBER_FOR_INVOICE_API_URL = `${Settings.urlLocal}/consecutiveByType/Remisión`;
const UPDATE_CONSECUTIVE_API_URL = `${Settings.urlLocal}/master/consecutive/update`;

const ConsecutiveService = {}

ConsecutiveService.getConsecutive = async () => {
    const response = await fetch(GET_CONSECUTIVE_API_URL, {
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    });
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response;
}

ConsecutiveService.updateConsecutive = async consecutive => {
    const response = await fetch(`${UPDATE_CONSECUTIVE_API_URL}/${consecutive.id}`, {
        method: 'PUT',
        body: JSON.stringify(consecutive),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    })

    if (!response.ok) {
        const error = await response.json()
        throw new Error(error.errorMessage);
    }

    return response;
}

ConsecutiveService.getConsecutiveNumberForInvoice = async () => {
    const response = await fetch(GET_CONSECUTIVE_NUMBER_FOR_INVOICE_API_URL, {
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`
        }
    });
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response.number + 1

}

export default ConsecutiveService;