import Settings from '../settings/ConfigServer';
import storageUtils from "../helpers/tokenUtils";

const GET_CONST_API_URL = `${Settings.urlLocal}/master/const/get-const`
const CREATE_CONST_API_URL = `${Settings.urlLocal}/master/const/create`

const ConstService = {}

ConstService.getConst = async () => {
    const response = await fetch(GET_CONST_API_URL, {
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`
        }
    });
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response;
}

ConstService.createConst = async(consts) => {
    const response = await fetch(CREATE_CONST_API_URL, {
        method: 'POST',
        body: JSON.stringify(consts),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    })

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;
}

export default ConstService