import React, { useCallback } from 'react'
import { Card, CardContent, CardHeader, Box, TextField, Button } from "@mui/material"
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { getWeekNumberCurrent } from "../../../helpers/utils"

//services
import SettleService from "../../../services/settle.service"
// import ConstService from "../../../services/const.service"

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const columns = [
    { field: 'id', headerName: 'ID', width: 60 },
    {
        field: 'weekNumber',
        headerName: '#Sem',
        width: 70,
        editable: false,
    },
    {
        field: 'invoiceNumber',
        headerName: '#Remisión',
        width: 90,
        editable: false,
    },
    {
        field: 'code',
        headerName: 'Código',
        width: 70,
        editable: false,
    },
    {
        field: 'reference',
        headerName: 'Referencia',
        width: 250,
        editable: false,
    },
    {
        field: 'amount',
        headerName: 'Cantidad',
        type: 'number',
        width: 90,
        editable: false,
    },
    {
        field: 'refWeight',
        headerName: 'Peso Referencia',
        type: 'number',
        width: 150,
        editable: false,
    },
    {
        field: 'weightInvoice',
        headerName: 'Peso Remisión',
        type: 'number',
        width: 170,
        editable: false,
    },
    {
        field: 'canTon',
        headerName: 'Peso Calculado',
        type: 'number',
        width: 170,
        editable: false,
    },
    {
        field: 'totalCanTon',
        headerName: 'Peso Basculado',
        type: 'number',
        width: 160,
        editable: false,
    },
    {
        field: 'price',
        headerName: 'Precio Cliente',
        type: 'number',
        width: 130,
        editable: false,
    },
    {
        field: 'totalClientPrice',
        headerName: 'Precio Total Cliente',
        type: 'number',
        width: 150,
        editable: false,
    },
    {
        field: 'modalidad',
        headerName: 'Modalidad',
        width: 120,
        editable: false,
    },
    {
        field: 'pricecontractor',
        headerName: 'Precio Contratista',
        type: 'number',
        width: 150,
        editable: false,
    }
    ,
    {
        field: 'pricecontractortotal',
        headerName: 'Precio Total Contratista',
        type: 'number',
        width: 170,
        editable: false,
    }
]

const columnsCustomer = [
    { field: 'id', headerName: 'ID', width: 60 },
    {
        field: 'weekNumber',
        headerName: '#Sem',
        width: 70,
        editable: false,
    },
    {
        field: 'invoiceNumber',
        headerName: '#Remisión',
        width: 90,
        editable: false,
    },
    {
        field: 'customerName',
        headerName: 'Nombre del cliente',
        width: 180,
        editable: false,
    },
    {
        field: 'customerIdentification',
        headerName: 'Identificación cliente',
        width: 150,
        editable: false,
    },
    {
        field: 'amount',
        headerName: 'Cant X Precio',
        type: 'number',
        width: 150,
        editable: false,
    },
    {
        field: 'priceCustomer',
        headerName: 'Precio Cliente',
        type: 'number',
        width: 150,
        editable: false,
    },
    {
        field: 'totalClientPrice',
        headerName: 'Precio Total Cliente',
        type: 'number',
        width: 150,
        editable: false,
    }
]

const columnsContractor = [
    { field: 'id', headerName: 'ID', width: 60 },
    {
        field: 'weekNumber',
        headerName: '#Sem',
        width: 70,
        editable: false,
    },
    {
        field: 'invoiceNumber',
        headerName: '#Remisión',
        width: 90,
        editable: false,
    },
    {
        field: 'contractorName',
        headerName: 'Nombre del contratista',
        width: 180,
        editable: false,
    },
    {
        field: 'modalidad',
        headerName: 'Modalidad',
        type: 'number',
        width: 150,
        editable: false,
    },
    {
        field: 'totalCanTon',
        headerName: 'Peso',
        type: 'number',
        width: 150,
        editable: false,
    },
    {
        field: 'contractorPrice',
        headerName: 'Precio Unidad',
        type: 'number',
        width: 150,
        editable: false,
    },
    {
        field: 'totalContractorPrice',
        headerName: 'Precio Total Contratista',
        type: 'number',
        width: 150,
        editable: false,
    }
]

const SettleReport = () => {
    const [value, setValue] = React.useState(0);

    const [loading, setLoading] = React.useState(false)
    const [settles, setSettles] = React.useState([])
    const [settlesCustomer, setSettlesCustomer] = React.useState([])
    const [settlesContractor, setSettlesContractor] = React.useState([])
    // const [consts, setConst] = React.useState([])
    const [error, setError] = React.useState(null)
    const [week, setWeek] = React.useState("")

    //Functions
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const fetchReportSettlesHandler = React.useCallback(async () => {
        setLoading(true)
        setError(null)
        if (week) {
            try {
                const settlesList = await SettleService.getReportSettles(week)

                const newSettle = settlesList.map((settle, index) => {
                    
                    let data = {
                        id: index,
                        weekNumber: settle.weekNumber,
                        invoiceNumber: settle.invoiceNumber,
                        code: settle.code,
                        reference: settle.name,
                        amount: settle.amount,
                        refWeight: settle.refWeight,
                        weightInvoice: settle.weightInvoice,
                        canTon: settle.canTon,
                        totalCanTon: settle.totalCanTon,
                        price: settle.CustomerPrice,
                        totalClientPrice: settle.CustomerPrice * settle.amount,
                        modalidad: settle.modalidad === "contractorPriceTalarasa" ? "Talarasa" : "Entresaca",
                        pricecontractor: settle.modalidad === "contractorPriceTalarasa" ? settle.contractorPriceTalarasa : settle.contractorPriceEntresaca,
                        pricecontractortotal: (settle.totalCanTon * (settle.modalidad === "contractorPriceTalarasa" ? settle.contractorPriceTalarasa : settle.contractorPriceEntresaca))
                    }
                    return data
                })
                
                setSettles(newSettle)
                setLoading(false);

            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
            //warnin this value of callback
            //||
            //\/
        }
    }, [week]);

    const getReportSettleByCustomer = useCallback(async() => {
        setLoading(true)
        setError(null)
        if (week) {
            try {
                const settlesList = await SettleService.getReportSettleByCustomers(week)
                const newSettle = settlesList.map((settle, index) => {
                    
                    let data = {
                        id: index,
                        weekNumber: settle.weekNumber,
                        invoiceNumber: settle.invoiceNumber,
                        customerName: settle.customerName,
                        customerIdentification: settle.customerIdentification,
                        amount: settle.amount,
                        priceCustomer: settle.CustomerPrice,
                        totalClientPrice: settle.CustomerPrice * settle.amount,
                    }
                    return data
                })
                setSettlesCustomer(newSettle)
                setLoading(false)
            } catch (error) {
                setError(error.message);
                setLoading(false)
            }
        }
    }, [week])

    const getReportSettleByContractor = useCallback(async() => {
        setLoading(true)
        setError(null)
        if (week) {
            try {
                const settlesList = await SettleService.getReportSettleByContractor(week)
                const newSettle = settlesList.map((settle, index) => {
                    
                    let data = {
                        id: index,
                        weekNumber: settle.weekNumber,
                        invoiceNumber: settle.invoiceNumber,
                        contractorName: settle.contractorName,
                        modalidad: settle.modalidad === "contractorPriceTalarasa" ? "Talarasa" : "Entresaca",
                        totalCanTon: settle.totalCanTon,
                        contractorPrice: settle.modalidad === "contractorPriceTalarasa" ? settle.contractorPriceTalarasa : settle.contractorPriceEntresaca,
                        totalContractorPrice: (settle.totalCanTon * (settle.modalidad === "contractorPriceTalarasa" ? settle.contractorPriceTalarasa : settle.contractorPriceEntresaca)),
                    }
                    return data
                })
                setSettlesContractor(newSettle)
                setLoading(false)
            } catch (error) {
                setError(error.message);
                setLoading(false)
            }
        }
    }, [week])

    const findSettlesData = () => {
        fetchReportSettlesHandler()
    }

    const findSettleCustomerdata = () => {
        getReportSettleByCustomer()
    }

    const findSettleContractordata = () => {
        getReportSettleByContractor()
    }

    // const getConsthandler = React.useCallback(async () => {
    //     setError(null)
    //     try {
    //         const getConst = await ConstService.getConst();
    //         setConst(getConst);
    //     } catch (error) {
    //         setError(error)
    //     }
    // }, [])

    // React.useEffect(() => {
    //     // void fetchReportSettlesHandler()
    //     void getConsthandler()
    // }, [getConsthandler])

    React.useEffect(() => {
        void setWeek(getWeekNumberCurrent())
    }, [])

    return (
        <div>
            <Card>
                <CardHeader
                    title={
                        <Typography variant='h5'>
                            Reportes de liquidaciones
                        </Typography>
                    }
                ></CardHeader>
                <Box sx={{ width: '97%', borderBottom: '1px solid #DEDEDE', margin: '0 auto' }} />
                <Box sx={{ width: '97%', borderBottom: '1px solid #DEDEDE', margin: '0 auto' }}>{error}</Box>
                <CardContent>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Reporte General" {...a11yProps(0)} />
                                <Tab label="Reporte por Contratista" {...a11yProps(1)} />
                                <Tab label="Reporte por cliente" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <Box
                                sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                            >
                                <Box>
                                    <TextField
                                        fullWidth
                                        id={'week'}
                                        name={'week'}
                                        label={'Semana del año'}
                                        variant="outlined"
                                        value={week}
                                        onChange={(e) => { setWeek(e.target.value) }}
                                        sx={{ mb: 2 }}
                                    />
                                    <Button variant="contained" onClick={() => { findSettlesData() }}>Consultar</Button>
                                </Box>
                                <Box sx={{ height: 430, width: '100%', mt: 3 }}>
                                    <DataGrid
                                        rows={settles}
                                        columns={columns}
                                        loading={loading}
                                        components={{ Toolbar: GridToolbar }}
                                        pageSize={5}
                                        rowsPerPageOptions={[5]}
                                        disableSelectionOnClick
                                        experimentalFeatures={{ newEditingApi: true }}
                                    />
                                </Box>
                            </Box>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                        <Box
                                sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                            >
                                <Box>
                                    <TextField
                                        fullWidth
                                        id={'week'}
                                        name={'week'}
                                        label={'Semana del año'}
                                        variant="outlined"
                                        value={week}
                                        onChange={(e) => { setWeek(e.target.value) }}
                                        sx={{ mb: 2 }}
                                    />
                                    <Button variant="contained" onClick={() => { findSettleContractordata() }}>Consultar</Button>
                                </Box>
                                <Box sx={{ height: 430, width: '100%', mt: 3 }}>
                                    <DataGrid
                                        rows={settlesContractor}
                                        columns={columnsContractor}
                                        loading={loading}
                                        components={{ Toolbar: GridToolbar }}
                                        pageSize={5}
                                        rowsPerPageOptions={[5]}
                                        disableSelectionOnClick
                                        experimentalFeatures={{ newEditingApi: true }}
                                    />
                                </Box>
                            </Box>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <Box
                                sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                            >
                                <Box>
                                    <TextField
                                        fullWidth
                                        id={'week'}
                                        name={'week'}
                                        label={'Semana del año'}
                                        variant="outlined"
                                        value={week}
                                        onChange={(e) => { setWeek(e.target.value) }}
                                        sx={{ mb: 2 }}
                                    />
                                    <Button variant="contained" onClick={() => { findSettleCustomerdata() }}>Consultar</Button>
                                </Box>
                                <Box sx={{ height: 430, width: '100%', mt: 3 }}>
                                    <DataGrid
                                        rows={settlesCustomer}
                                        columns={columnsCustomer}
                                        loading={loading}
                                        components={{ Toolbar: GridToolbar }}
                                        pageSize={5}
                                        rowsPerPageOptions={[5]}
                                        disableSelectionOnClick
                                        experimentalFeatures={{ newEditingApi: true }}
                                    />
                                </Box>
                            </Box>
                        </TabPanel>
                    </Box>
                </CardContent>
            </Card>
        </div>
    )
}

export default SettleReport