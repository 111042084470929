import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import StyledTableCell from "../../layouts/table/StyledTableCell";
import StyledTableRow from "../../layouts/table/StyledTableRow";
import {Card, CardContent, CardHeader} from "@mui/material";
import Typography from "@mui/material/Typography";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Button from "@mui/material/Button";
import React, {useCallback, useEffect, useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import WeekModal from "./WeekModal";
import DeleteItemModal from "../DeleteItemModal";
import WeekService from "../../../services/week.service";
import {toast} from "react-toastify";
import {convertDate2} from "../../../helpers/utils";


const initialWeek = {
    id: '',
    startDate: '',
    endDate: '',
    weekNumber: '',
    year:''
}

const WeekList = () => {

    const [weeks, setWeeks] = useState([]);
    const [selectedWeek, setSelectedWeek] = useState(initialWeek)
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    //modals
    const [openCreateWeekModal, setOpenCreateWeekModal] = useState(false);
    const [openEditWeekModal, setOpenEditWeekModal] = useState(false);
    const [openDeleteWeekModal, setOpenDeleteWeekModal] = useState(false);

    const fetchWeeksHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null)
        try {
            const weekList = await WeekService.getWeeks();
            setWeeks(weekList);

        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false);
    }, []);

    const removeWeekHandler = async () => {
        try {
            await WeekService.removeWeek(selectedWeek.id);
            toast.success('Se ha eliminado correctamente', {delay: 1000});
            toggleModal('remove', false)
        } catch (e) {
            console.error(e);
            toast.error('There was an error trying to remove week', {delay: 1000});
        }
    };

    const openCreateWeekHandler = () => {
        setSelectedWeek(initialWeek)
        toggleModal('create', true);
    }

    const openEditWeekHandler = (week) => {
        setSelectedWeek(week)
        toggleModal('edit', true);
    }

    const openRemoveWeekHandler = (week) => {
        setSelectedWeek(week)
        toggleModal('remove', true);
    }

    const toggleModal = (modalType, open) => {
        switch (modalType) {
            case 'create':
                setOpenCreateWeekModal(open);
                break;
            case 'edit':
                setOpenEditWeekModal(open);
                break;
            case 'remove':
                setOpenDeleteWeekModal(open);
                break;
            default:
                console.error(`Modal type ${modalType} is not valid`);
        }

    }

    useEffect(() => {
        if (!openCreateWeekModal && !openDeleteWeekModal && !openEditWeekModal) {
            void fetchWeeksHandler();
        }

    }, [fetchWeeksHandler, openCreateWeekModal, openDeleteWeekModal, openEditWeekModal])


    return (
        <>
            <Card>
                <CardHeader
                    title={
                        <Typography variant='h4'>
                            Semanas
                        </Typography>
                    }
                    action={
                        <Button variant="outlined" startIcon={<ControlPointIcon/>}
                                onClick={openCreateWeekHandler}>
                            Añadir nuevo
                        </Button>
                    }
                >
                </CardHeader>
                <CardContent>
                    {isLoading && weeks.length <= 0 && <p>Cargando semanas...</p>}
                    {!isLoading && !error && weeks.length === 0 && <p>No se han creado semanas.</p>}
                    {error && <p> {error} </p>}
                    {weeks.length > 0 &&
                        <TableContainer component={Paper} sx={{maxWidth: '100%'}}>
                            <Table sx={{minWidth: 400}}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="right">Año</StyledTableCell>
                                        <StyledTableCell align="right">Número de semana</StyledTableCell>
                                        <StyledTableCell>Fecha inicio</StyledTableCell>
                                        <StyledTableCell align="right">Fecha fin</StyledTableCell>
                                        <StyledTableCell align="right"/>
                                        <StyledTableCell align="right"/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {weeks.map((week) => (
                                        <StyledTableRow
                                            key={week.id}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell align="right">{week.year}</TableCell>
                                            <TableCell align="right">{week.weekNumber}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {convertDate2(week.startDate)}
                                            </TableCell>
                                            <TableCell align="right">{convertDate2(week.endDate)}</TableCell>
                                            
                                            <TableCell align="right">
                                                <Button onClick={() => openEditWeekHandler(week)}>
                                                    <EditIcon/>
                                                </Button>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button onClick={() => openRemoveWeekHandler(week)}>
                                                    <DeleteIcon color={'warning'}/>
                                                </Button>
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                </CardContent>
            </Card>

            {/*modals*/}
            <WeekModal open={openCreateWeekModal}
                       modalType="CREATE"
                       week={selectedWeek}
                       onClose={() => toggleModal('create', false)}
                       weeksSize={weeks.length}/>
            <WeekModal open={openEditWeekModal}
                       modalType="UPDATE"
                       week={selectedWeek}
                       onClose={() => toggleModal('edit', false)}
                       weeksSize={weeks.length}/>
            <DeleteItemModal open={openDeleteWeekModal}
                             onClose={() => toggleModal('remove', false)}
                             onRemove={removeWeekHandler}
                             itemName={selectedWeek.weekNumber}
                             item={"semana"}/>
        </>

    )
}

export default WeekList;