import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import StyledTableCell from "../../layouts/table/StyledTableCell";
import StyledTableRow from "../../layouts/table/StyledTableRow";
import { Card, CardContent, CardHeader } from "@mui/material";
import Typography from "@mui/material/Typography";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Button from "@mui/material/Button";
import React, { useCallback, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ScaleIcon from '@mui/icons-material/Scale';
import DeleteItemModal from "../DeleteItemModal";
import { toast } from "react-toastify";
import InvoiceService from "../../../services/invoice.service";
import InvoiceModal from "./InvoiceModal";
import useRole from "../../../hooks/useRole";
import Roles from "../../../helpers/roles";
import InvoiceModalPDF from "./InvoiceModalPDF";
import ArticleIcon from '@mui/icons-material/Article';
import { convertDate2 } from "../../../helpers/utils";
import UpdateInvoiceWeight from "./UpdateInvoiceWeight";
import InvoiceUpdateModal from "./InvoiceUpdateModal";


const initialInvoice = {
    id: '',
    invoiceNumber: '',
    destination: '',
    state: '',
    entryTime: '00:00',
    departureTime: '00:00',
    customerId: '',
    createdAt: Date.now(),
    zoneId: '',
    driverId: '',
    dispatcherId: '',
    vehicleId: '',
    freight: '',
    weight: 0,
    woodType: '',
    order: '',
    delivery: '',
    bill: '',
    safeConduct: '',
    references: [],
}

const InvoiceList = () => {

    const [invoices, setInvoices] = useState([]);
    const [selectedInvoice, setSelectedInvoice] = useState(initialInvoice)
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { role } = useRole();

    //modals
    const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
    const [openCreateInvoiceModal, setOpenCreateInvoiceModal] = useState(false);
    const [openEditInvoiceModal, setOpenEditInvoiceModal] = useState(false);
    const [openDeleteInvoiceModal, setOpenDeleteInvoiceModal] = useState(false);
    const [openUpdateInvoiceWeight, setOpenUpdateInvoiceWeight] = useState(false)

    const fetchInvoicesHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null)
        const invoiceList = await InvoiceService.getInvoices();
        try {

            setInvoices(invoiceList);

        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false);
    }, []);

    const removeInvoiceHandler = async () => {
        try {
            await InvoiceService.removeInvoice(selectedInvoice.id);
            toast.success('Se ha eliminado correctamente');
            toggleModal('remove', false)
        } catch (e) {
            console.error(e);
            toast.error('There was an error trying to remove this invoice');
        }
    };

    const openCreateInvoiceHandler = () => {
        setSelectedInvoice(() => initialInvoice)
        toggleModal('create', true);
    }

    const openInvoiceModalHandler = (invoice) => {
        setSelectedInvoice(() => invoice)
        toggleModal('view', true);
    }

    const openEditInvoiceHandler = (invoice) => {
        setSelectedInvoice(() => invoice)
        toggleModal('edit', true);
    }

    const openRemoveInvoiceHandler = (invoice) => {
        setSelectedInvoice(invoice)
        toggleModal('remove', true);
    }

    const openUpdateInvoiceWeightHandler = (invoice) => {
        setSelectedInvoice(() => invoice)
        setOpenUpdateInvoiceWeight(true)
    }

    const toggleModal = (modalType, open) => {
        switch (modalType) {
            case 'create':
                setOpenCreateInvoiceModal(open);
                break;
            case 'edit':
                setOpenEditInvoiceModal(open);
                break;
            case 'remove':
                setOpenDeleteInvoiceModal(open);
                break;
            case 'view':
                setOpenInvoiceModal(open)
                break;
            default:
                console.error(`Modal type ${modalType} is not valid`);
        }

    }

    useEffect(() => {
        if (!openCreateInvoiceModal && !openEditInvoiceModal && !openDeleteInvoiceModal && !openInvoiceModal) {
            void fetchInvoicesHandler();
        }

    }, [fetchInvoicesHandler, openCreateInvoiceModal, openDeleteInvoiceModal,
        openEditInvoiceModal, openInvoiceModal])


    return (
        <>
            <Card>
                <CardHeader
                    title={
                        <Typography variant='h4'>
                            Remisiones
                        </Typography>
                    }
                    action={
                        <Button variant="outlined" startIcon={<ControlPointIcon />}
                            onClick={openCreateInvoiceHandler}>
                            Añadir nuevo
                        </Button>
                    }
                >
                </CardHeader>
                <CardContent>
                    {isLoading && invoices.length <= 0 && <p>Cargando remisiones...</p>}
                    {!isLoading && !error && invoices.length === 0 && <p>No se han creado remisiones.</p>}
                    {error && <p> {error} </p>}
                    {invoices.length > 0 &&
                        <TableContainer component={Paper} sx={{ maxWidth: '100%' }}>
                            <Table sx={{ minWidth: 600 }}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Número de Remisión</StyledTableCell>
                                        <StyledTableCell align="right">Cliente</StyledTableCell>
                                        <StyledTableCell align="right">Destino</StyledTableCell>
                                        <StyledTableCell align="right">Estado</StyledTableCell>
                                        <StyledTableCell align="right">Fecha</StyledTableCell>
                                        <StyledTableCell align="right" />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {invoices.map((invoice) => (
                                        <StyledTableRow
                                            key={invoice.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {invoice.invoiceNumber}
                                            </TableCell>
                                            <TableCell align="right">{invoice.customer?.name}</TableCell>
                                            <TableCell align="right">{invoice.destination}</TableCell>
                                            <TableCell align="right">{invoice.state}</TableCell>
                                            <TableCell align="right">{convertDate2(invoice.createdAt)}</TableCell>
                                            <TableCell align="right">
                                                <Button onClick={() => openInvoiceModalHandler(invoice)}>
                                                    <ArticleIcon />
                                                </Button>
                                                {
                                                    invoice.state !== 'Liquidado' && <>
                                                        <Button onClick={() => openEditInvoiceHandler(invoice)}>
                                                            <EditIcon />
                                                        </Button>
                                                    </>
                                                }
                                                {role === Roles.Admin &&
                                                    <>
                                                        {
                                                            invoice.state !== 'Liquidado' && <>
                                                                <Button onClick={() => openRemoveInvoiceHandler(invoice)}>
                                                                    <DeleteIcon color={'warning'} />
                                                                </Button>
                                                            </>
                                                        }
                                                    </>
                                                }
                                                {
                                                    invoice.state !== 'Liquidado' && <>

                                                        <Button onClick={() => openUpdateInvoiceWeightHandler(invoice)}>
                                                            <ScaleIcon color={'success'} />
                                                        </Button>
                                                    </>
                                                }
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                </CardContent>
            </Card>

            {/*Modals*/}
            <InvoiceModalPDF open={openInvoiceModal}
                invoiceId={selectedInvoice.id}
                onClose={() => toggleModal('view', false)}
            />
            <InvoiceModal open={openCreateInvoiceModal}
                modalType="CREATE"
                invoice={selectedInvoice}
                onClose={() => toggleModal('create', false)}
            />
            <InvoiceUpdateModal open={openEditInvoiceModal}
                modalType="EDIT"
                invoice={selectedInvoice}
                onClose={() => toggleModal('edit', false)}
            />
            <UpdateInvoiceWeight
                open={openUpdateInvoiceWeight}
                invoice={selectedInvoice}
                onClose={setOpenUpdateInvoiceWeight}
            />
            {role === Roles.Admin &&
                <>
                    <DeleteItemModal open={openDeleteInvoiceModal}
                        onClose={() => toggleModal('remove', false)}
                        onRemove={removeInvoiceHandler}
                        itemName={selectedInvoice.invoiceNumber}
                        item={"remisión"} />
                </>
            }
        </>

    )
}

export default InvoiceList;