import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import StyledTableCell from "../../layouts/table/StyledTableCell";
import StyledTableRow from "../../layouts/table/StyledTableRow";
import {Card, CardContent, CardHeader} from "@mui/material";
import Typography from "@mui/material/Typography";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Button from "@mui/material/Button";
import React, {useCallback, useEffect, useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InterlocutorTypeModal from "./InterlocutorTypeModal";
import DeleteItemModal from "../DeleteItemModal";
import InterlocutorTypeService from "../../../services/interlocutorType.service";
import {toast} from "react-toastify";


const initialInterlocutorType = {
    id: '',
    type: '',
    active: true
}

const InterlocutorTypeList = () => {

    const [interlocutorTypes, setInterlocutorTypes] = useState([]);
    const [selectedInterlocutorType, setSelectedInterlocutorType] = useState(initialInterlocutorType)
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    //modals
    const [openCreateInterlocutorTypeModal, setOpenCreateInterlocutorTypeModal] = useState(false);
    const [openEditInterlocutorTypeModal, setOpenEditInterlocutorTypeModal] = useState(false);
    const [openDeleteInterlocutorTypeModal, setOpenDeleteInterlocutorTypeModal] = useState(false);

    const fetchInterlocutorTypesHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null)
        try {
            const interlocutorTypeList = await InterlocutorTypeService.getInterlocutorTypes();
            // console.log(interlocutorTypeList);
            setInterlocutorTypes(interlocutorTypeList);

        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false);
    }, []);

    const removeInterlocutorTypeHandler = async () => {
        try {
            await InterlocutorTypeService.removeInterlocutorType(selectedInterlocutorType.id);
            toast.success('Se ha eliminado correctamente', {delay: 1000});
            toggleModal('remove', false)
        } catch (e) {
            console.error(e);
            toast.error('There was an error trying to remove interlocutor Type', {delay: 1000});
        }
    };

    const openCreateInterlocutorTypeHandler = () => {
        setSelectedInterlocutorType(initialInterlocutorType)
        toggleModal('create', true);
    }

    const openEditInterlocutorTypeHandler = (interlocutorType) => {
        setSelectedInterlocutorType(interlocutorType)
        toggleModal('edit', true);
    }

    const openRemoveInterlocutorTypeHandler = (interlocutorType) => {
        setSelectedInterlocutorType(interlocutorType)
        toggleModal('remove', true);
    }

    const toggleModal = (modalType, open) => {
        switch (modalType) {
            case 'create':
                setOpenCreateInterlocutorTypeModal(open);
                break;
            case 'edit':
                setOpenEditInterlocutorTypeModal(open);
                break;
            case 'remove':
                setOpenDeleteInterlocutorTypeModal(open);
                break;
            default:
                console.error(`Modal type ${modalType} is not valid`);
        }

    }

    useEffect(() => {
        if (!openCreateInterlocutorTypeModal && !openDeleteInterlocutorTypeModal && !openEditInterlocutorTypeModal) {
            void fetchInterlocutorTypesHandler();
        }

    }, [fetchInterlocutorTypesHandler, openCreateInterlocutorTypeModal, openDeleteInterlocutorTypeModal, openEditInterlocutorTypeModal])


    return (
        <>
            <Card>
                <CardHeader
                    title={
                        <Typography variant='h4'>
                            Tipos de Interlocutor
                        </Typography>
                    }
                    action={
                        <Button variant="outlined" startIcon={<ControlPointIcon/>}
                                onClick={openCreateInterlocutorTypeHandler}>
                            Añadir nuevo
                        </Button>
                    }
                >
                </CardHeader>
                <CardContent>
                    {isLoading && interlocutorTypes.length <= 0 && <p>Cargando Tipos de interlocutor...</p>}
                    {!isLoading && !error && interlocutorTypes.length === 0 && <p>No se han creado tipos de interlocutor.</p>}
                    {error && <p> {error} </p>}
                    {interlocutorTypes.length > 0 &&
                        <TableContainer component={Paper} sx={{maxWidth: '100%'}}>
                            <Table sx={{minWidth: 400}}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Tipo</StyledTableCell>
                                        <StyledTableCell align="right">Estado</StyledTableCell>
                                        <StyledTableCell align="right"/>
                                        <StyledTableCell align="right"/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {interlocutorTypes.map((interlocutorType) => (
                                        <StyledTableRow
                                            key={interlocutorType.id}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {interlocutorType.type}
                                            </TableCell>
                                            <TableCell
                                                align="right">{interlocutorType.active ? 'Activo' : 'Inactivo'}</TableCell>
                                            <TableCell align="right">
                                                <Button
                                                    onClick={() => openEditInterlocutorTypeHandler(interlocutorType)}>
                                                    <EditIcon/>
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    onClick={() => openRemoveInterlocutorTypeHandler(interlocutorType)}>
                                                    <DeleteIcon color={'warning'}/>
                                                </Button>
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                </CardContent>
            </Card>

            {/*modals*/}
            <InterlocutorTypeModal open={openCreateInterlocutorTypeModal}
                                   modalType="CREATE"
                                   interlocutorType={selectedInterlocutorType}
                                   onClose={() => toggleModal('create', false)}
                                   interlocutorTypesSize={interlocutorTypes.length}/>
            <InterlocutorTypeModal open={openEditInterlocutorTypeModal}
                                   modalType="UPDATE"
                                   interlocutorType={selectedInterlocutorType}
                                   onClose={() => toggleModal('edit', false)}
                                   interlocutorTypesSize={interlocutorTypes.length}/>
            <DeleteItemModal open={openDeleteInterlocutorTypeModal}
                             onClose={() => toggleModal('remove', false)}
                             onRemove={removeInterlocutorTypeHandler}
                             itemName={selectedInterlocutorType.type}
                             item={"tipo interlocutor"}/>
        </>

    )
}

export default InterlocutorTypeList;