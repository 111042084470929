import React from 'react'
import {
    Typography,
    Button,
    Modal,
    IconButton,
    Box,
    TextField
} from '@mui/material';
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import InvoiceService from '../../../services/invoice.service';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0.8rem',
    width: '40%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingBottom: '10px',
    maxWidth: '90%',
    maxHeight: '80%',
};

const UpdateInvoiceWeight = (props) => {
    const [weight, setWeight] = React.useState(0)
    const closeModalHandler = () => props.onClose(false);
    const inputChangeHandler = (e) => setWeight(e.target.value)
    const submitUpdateInvoiceWeightHandler = async (e) => {
        e.preventDefault()
        try {
            await InvoiceService.UpdateInvoiceWeight(props.invoice.id, weight)
            setWeight(weight)
            toast.success('Se ha actualizado el peso correctamente', 1500)
            closeModalHandler()
        } catch (error) {
            console.log(error)
            toast.error('No se pudo actualizar el peso', 1500)
        }
    }

    // const fetchInvoiceData = React.useCallback(async () => {
    //     if(props.invoice.id){
    //         try {
    //             const invoice = await InvoiceService.getInvoice(props.invoice.id)
    //             console.log(invoice)
    //         } catch (error) {
    //             console.log(error)
    //             toast.error('No se pudo traer la información de la remisión', 1500)
    //         }
    //     }

    // }, [props.invoice.id])

    React.useEffect(() => {
        setWeight(props.invoice.weight)
    }, [props.invoice.weight])

    return (
        <Modal
            open={props.open}
            onClose={closeModalHandler}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {/* Header Modal */}
                <Box sx={{ bgcolor: (theme) => theme.palette.primary.main, }}>
                    <Box
                        sx={{
                            display: 'flex'
                        }}
                    >
                        <Box
                            sx={{ width: '90%', py: 2, px: 2 }}
                        >
                            <Typography
                                variant={'h3'}
                                sx={{
                                    fontSize: 24,

                                    color: (theme) => theme.palette.secondary.main
                                }}
                            >
                                Agregar peso
                            </Typography>
                            <Typography
                                variant={'p'}
                                sx={{
                                    fontSize: 12,
                                    color: (theme) => theme.palette.secondary.main
                                }}
                            >
                                Agrega el peso de la remisión que contiene el vehículo transportador
                            </Typography>
                        </Box>
                        <Box
                            sx={{ width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <IconButton
                                onClick={closeModalHandler}
                                sx={{
                                    background: (theme) => theme.palette.grey[300],
                                    margin: 0
                                }}
                            >
                                <CloseIcon sx={{ width: 14, height: 14, padding: 0, margin: 0, color: (theme) => theme.palette.primary.main }} />
                            </IconButton>
                        </Box>
                    </Box>

                </Box>
                {/* END Header Modal */}
                <Box sx={{ p: 2 }}>
                    <Box component="form" onSubmit={submitUpdateInvoiceWeightHandler}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2. }}>
                            <Box sx={{ width: '100%', mr: 2 }}>
                                <TextField name="weight" label="Peso en KG"
                                    variant="outlined"
                                    size="small"
                                    value={weight}
                                    onChange={inputChangeHandler}
                                    fullWidth
                                    type="number"
                                    margin="dense" />
                            </Box>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                                <Button variant="outlined" color="error" onClick={closeModalHandler}
                                    sx={{ marginRight: '10px' }}>Cancelar</Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    sx={{
                                        width: '40%',
                                        fontSize: '0.9rem',
                                        borderRadius: '05rem',
                                        backgroundColor: (theme) => theme.palette.primary.main,
                                        color: (theme) => theme.palette.secondary.main
                                    }}
                                >
                                    Actualizar Peso
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default UpdateInvoiceWeight