import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import InterlocutorService from "../../../services/interlocutor.service";
import InvoiceService from "../../../services/invoice.service";
import PriceService from "../../../services/price.service"
import ZoneService from "../../../services/zone.service";
import VehicleService from "../../../services/vehicle.service";
import ReferenceService from "../../../services/reference.service";
import IconButton from "@mui/material/IconButton";
import ConsecutiveService from "../../../services/consecutive.service";
import InvoiceStates from "../../../helpers/InvoiceStates";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from "@mui/icons-material/Delete";
import StyledGreenTableCell from "../../layouts/table/StyledGreenTableCell";
import { Divider, FormControl, FormControlLabel, FormLabel, RadioGroup } from "@mui/material";
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import CloseIcon from '@mui/icons-material/Close';
import { convertDate , convertDate3 } from "../../../helpers/utils";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0.8rem',
    width: '65%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingBottom: '10px',
    overflow: 'scroll',
    maxWidth: '90%',
    maxHeight: '80%',
};

const modalidad = [
    { label: 'Entresaca', value: "contractorPriceEntresaca" },
    { label: 'Talarasa', value: "contractorPriceTalarasa" }
]

const InvoiceModal = props => {

    const isCreateModal = props.modalType === 'CREATE'
    const [invoice, setInvoice] = useState({ ...props.invoice })
    const [customers, setCustomers] = useState([])
    const [zones, setZones] = useState([])
    const [drivers, setDrivers] = useState([])
    const [dispatchers, setDispatchers] = useState([])
    const [vehicles, setVehicles] = useState([])
    const [references, setReferences] = useState([])
    const [prices, setPrices] = useState([])
    const [selectedReferenceId, setSelectedReferenceId] = useState("")
    const [refModalidad, setRefModalidad] = useState("")
    const [referenceAmount, setReferenceAmount] = useState(1)
    const [contractors, setContractors] = useState([])
    const [selectedContractorId, setSelectedContractorId] = useState("")

    // const MODAL_TITLE = isCreateModal ? 'Crear Remisión' : 'Editar Remisión'
    const MODAL_CONFIRM_BUTTON = isCreateModal ? 'Crear' : 'Guardar'


    // console.log(convertDate(invoice.createdAt))
    // console.log(invoice.references);

    // const handleClose = () => {
    //     props.setOpen(false);
    // };

    const inputChangeHandler = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setInvoice(invoice => {
            return {
                ...invoice,
                [name]: value
            }
        })
    }

    const referenceChangeHandler = e => {
        setSelectedReferenceId(() => e.target.value)
    }

    const modalidadChangeHandler = e => {
        setRefModalidad(() => e.target.value)
    }

    const referenceAmountChangeHandler = e => {
        setReferenceAmount(() => e.target.value)
    }

    const contractorChangeHandler = e => {
        setSelectedContractorId(() => e.target.value)
    }

    const addReferenceHandler = () => {
        if(invoice.customerId){
            if (parseInt(referenceAmount) > 0) {
                let reference = invoice.references?.find(ref => ref.id === selectedReferenceId);
                let contractor = contractors.find(contractor => contractor.id === selectedContractorId)
                if (!reference) {
                    if (selectedReferenceId !== "" && referenceAmount > 0 && refModalidad !== "" && contractor !== undefined) {
                        addReference(selectedReferenceId, referenceAmount, refModalidad, contractor);
                        setReferenceAmount(1)
                        setSelectedReferenceId("")
                        setRefModalidad("")
                        setSelectedContractorId("")
                    } else {
                        toast.error("Todos los campos son requeridos para agregar una referencia", { delay: 200 });
                    }
                } else {
                    if (refModalidad !== reference.modalidad && contractor.id !== reference.contractorId) {
                        // console.log('validación condición 1 cumplida')
                        let findRefValidation1 = invoice.references.find(ref => ref.id === selectedReferenceId && ref.modalidad === refModalidad && ref.contractorId === contractor.id)
                        // console.log(findRefValidation1)
                        if (!findRefValidation1) {
                            addReference(selectedReferenceId, referenceAmount, refModalidad, contractor);
                            setReferenceAmount(1)
                            setSelectedReferenceId("")
                            setRefModalidad("")
                            setSelectedContractorId("")
                        } else {
                            // console.log('Actualizar actual')
                            updateReferenceAmount(selectedReferenceId, referenceAmount, refModalidad, contractor.id);
                            setReferenceAmount(1)
                            setSelectedReferenceId("")
                            setRefModalidad("")
                            setSelectedContractorId("")
                        }
                    } else {
                        if (refModalidad !== reference.modalidad && contractor.id === reference.contractorId) {
                            // console.log('validación condición 2 cumplida')
                            let findRefValidation2 = invoice.references.find(ref => ref.id === selectedReferenceId && ref.modalidad === refModalidad && ref.contractorId === contractor.id)
                            // console.log(findRefValidation2)
                            if (!findRefValidation2) {
                                addReference(selectedReferenceId, referenceAmount, refModalidad, contractor);
                                setReferenceAmount(1)
                                setSelectedReferenceId("")
                                setRefModalidad("")
                                setSelectedContractorId("")
                            } else {
                                // console.log('Actualizar actual')
                                updateReferenceAmount(selectedReferenceId, referenceAmount, refModalidad, contractor.id);
                                setReferenceAmount(1)
                                setSelectedReferenceId("")
                                setRefModalidad("")
                                setSelectedContractorId("")
                            }
                        } else {
                            if (refModalidad === reference.modalidad && contractor.id !== reference.contractorId) {
                                // console.log('validación condición 3 cumplida')
                                let findRefValidation3 = invoice.references.find(ref => ref.id === selectedReferenceId && ref.modalidad === refModalidad && ref.contractorId === contractor.id)
                                // console.log(findRefValidation3)
                                if (!findRefValidation3) {
                                    addReference(selectedReferenceId, referenceAmount, refModalidad, contractor);
                                    setReferenceAmount(1)
                                    setSelectedReferenceId("")
                                    setRefModalidad("")
                                    setSelectedContractorId("")
                                } else {
                                    // console.log('Actualizar actual')
                                    updateReferenceAmount(selectedReferenceId, referenceAmount, refModalidad, contractor.id);
                                    setReferenceAmount(1)
                                    setSelectedReferenceId("")
                                    setRefModalidad("")
                                    setSelectedContractorId("")
                                }
                            } else {
                                // console.log('Actualizar actual')
                                updateReferenceAmount(selectedReferenceId, referenceAmount, refModalidad, contractor.id);
                                setReferenceAmount(1)
                                setSelectedReferenceId("")
                                setRefModalidad("")
                                setSelectedContractorId("")
                            }
                        }
                    }
                }
            }
        }else{
            toast.error("Debes seleccionar un cliente")
            return;
        }
    }

    const addReference = (referenceId, amount, refModalidad, contractor) => {
        const reference = references.find(ref => ref.id === referenceId);
        const selectedRefPrice = prices.find((p) => p.referenceId === referenceId && p.interlocutorId === invoice.customerId)
        // console.log(selectedRefPrice)
        if(selectedRefPrice !== undefined){
            if (reference) {
                setInvoice(invoice => {
                    return {
                        ...invoice,
                        references: [...invoice.references, {
                            id: reference.id,
                            name: reference.name,
                            amount: amount,
                            contractor: contractor.name,
                            contractorId: contractor.id,
                            modalidad: refModalidad === "contractorPriceEntresaca" ? "contractorPriceEntresaca" : "contractorPriceTalarasa",
                            contractorPriceTalarasa: reference.contractorPriceTalarasa,
                            contractorPriceEntresaca: reference.contractorPriceEntresaca,
                            currentSalePrice: selectedRefPrice.salePrice,
                            canTon: (parseInt(amount) * reference.weight) / 1000,
                            totalCanTon: (parseInt(amount) * reference.weight) / 1000,
                            totalContractorPrice: refModalidad === "contractorPriceEntresaca" ? (((parseInt(amount) * reference.weight) / 1000) * reference.contractorPriceEntresaca) : (((parseInt(amount) * reference.weight) / 1000) * reference.contractorPriceTalarasa),
                            currentContractorPrice: refModalidad === "contractorPriceEntresaca" ? (((parseInt(amount) * reference.weight) / 1000) * reference.contractorPriceEntresaca) : (((parseInt(amount) * reference.weight) / 1000) * reference.contractorPriceTalarasa),
                        }]
                    }
                })
            }
        }else{
            toast.error("El cliente no tiene creado el precio de la referencia", 4000)
            return
        }

    }

    const updateReferenceAmount = (referenceId, amount, refModalidad, contractorId) => {
        const newReferences = invoice.references?.map(ref => {
            // console.log(ref.modalidad, refModalidad)
            if (ref.id === referenceId && ref.modalidad === refModalidad && ref.contractorId === contractorId) {
                // console.log('condicion 1 cumplida')
                return {
                    ...ref,
                    amount: parseInt(ref.amount) + parseInt(amount) > 0 ? parseInt(ref.amount) + parseInt(amount) : 1
                }
            }
            return ref;
        });
        setInvoice(invoice => {
            return {
                ...invoice,
                references: newReferences
            }
        });
    }

    const removeReferenceFromList = index => {
        const newReferences = invoice.references?.filter((ref, i) => i !== index);
        setInvoice(invoice => {
            return {
                ...invoice,
                references: newReferences
            }
        });
    }

    const submitInvoiceHandler = async e => {
        e.preventDefault();
        try {
            if (isCreateModal) {
                let updateDate = invoice.createdAt
                let newdate = convertDate3(updateDate)
                let newInvoice = {...invoice, createdAt:newdate}
                //console.log(invoice)
                await InvoiceService.createInvoice(newInvoice);
            } else {
                let invoiceWithState = {}
                if (invoice.state === "Liquidado") {
                    invoiceWithState = {
                        ...invoice,
                        state: InvoiceStates.settled
                    }
                    await InvoiceService.updateInvoice(invoiceWithState);
                } else {
                    invoiceWithState = {
                        ...invoice,
                        state: InvoiceStates.corrected,
                        weight: 0
                    }
                    //console.log(invoiceWithState)
                    await InvoiceService.updateInvoice(invoiceWithState);
                }
            }

            const successMessage = isCreateModal ? 'Se ha creado correctamente' : 'Se ha actualizado correctamente';
            toast.success(successMessage, { delay: 200 });

            closeModalHandler();
            setReferenceAmount(1)
            setSelectedReferenceId("")
            setRefModalidad("")
            setSelectedContractorId("")
        } catch (e) {
            console.error(e);
            toast.error('Ocurrió un error, vuelva a intentar', { delay: 200 });
        }
    }

    const fetchFormValuesHandler = useCallback(async () => {
        try {
            const customerList = await InterlocutorService.getCustomers();
            setCustomers(customerList);

            const zoneList = await ZoneService.getActiveZones();
            setZones(zoneList)

            const drivers = await InterlocutorService.getDrivers();
            setDrivers(drivers);

            const dispatcherList = await InterlocutorService.getDispatchers();
            setDispatchers(dispatcherList);


            const referenceList = await ReferenceService.getActiveReferences();
            setReferences(referenceList);

            const contractorList = await InterlocutorService.getContractors();
            setContractors(contractorList)

            const pricesList = await PriceService.getActivePrices()
            setPrices(pricesList)

        } catch (error) {
            console.error(error.message);
        }
    }, []);

    const fetchInvoiceNumber = useCallback(async () => {
        try {
            const invoiceNumber = await ConsecutiveService.getConsecutiveNumberForInvoice();
            setInvoice(inv => {
                return {
                    ...inv,
                    invoiceNumber
                }
            })
        } catch (error) {
            console.error(error.message);
        }
    }, [])

    const fetchVehicles = useCallback(async (driverId) => {
        const vehicleList = await VehicleService.getVehiclesByDriver(driverId);
        setVehicles(vehicleList);
    }, [])

    const fetchDetailInvoicereference = useCallback(async () => {
        setInvoice(invoice => {
            return {
                ...invoice,
                references: []
            }
        })
        if (invoice.id !== "") {
            if (!isCreateModal) {
                setInvoice(invoice => {
                    return {
                        ...invoice,
                        references: []
                    }
                })
                const detailInvoiceReferences = await InvoiceService.getDeatlsInvoiceReferences(invoice.id)
                //console.log(detailInvoiceReferences)
                // if (detailInvoiceReferences.lenght > 0) {
                detailInvoiceReferences.forEach((reference) => {
                    let getReference = references.find(ref => ref.id === reference.referenceId)
                    let contractor = contractors.find(contractor => contractor.id === reference.contractorId)
                    let newData = {
                        id: reference.referenceId,
                        name: getReference.name,
                        amount: reference.amount,
                        contractor: contractor.name,
                        contractorId: contractor.id,
                        modalidad: reference.modalidad,
                        contractorPriceTalarasa: reference.contractorPriceTalarasa,
                        contractorPriceEntresaca: reference.contractorPriceEntresaca,
                        canTon: (parseInt(reference.amount) * getReference.weight) / 1000,
                        totalCanTon: (parseInt(reference.amount) * getReference.weight) / 1000,
                        totalContractorPrice: reference.totalContractorPrice,
                        currentContractorPrice: reference.currentContractorPrice,
                        currentSalePrice: reference.currentSalePrice
                    }
                    setInvoice(invoice => {
                        return {
                            ...invoice,
                            references: [...invoice.references, newData]
                        }
                    })
                })
                // }
            }
        }
    }, [invoice.id, references, contractors, isCreateModal])

    useEffect(() => {
        if (props.open) {
            setInvoice({ ...props.invoice });
            if (isCreateModal) { void fetchInvoiceNumber() } else { void fetchDetailInvoicereference() }
        }
    }, [props.invoice, fetchInvoiceNumber, isCreateModal, props.open, fetchDetailInvoicereference]);

    useEffect(() => {
        void fetchFormValuesHandler();
    }, [fetchFormValuesHandler])

    useEffect(() => {
        if (invoice.driverId) {
            void fetchVehicles(invoice.driverId);
        }
    }, [invoice, fetchVehicles])

    // useEffect(() => {
    //     void fetchDetailInvoicereference()
    // }, [fetchDetailInvoicereference])

    const closeModalHandler = () => props.onClose();

    return (
        <>
            <Modal
                open={props.open}
                onClose={closeModalHandler}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box>
                        {/* Header Modal */}
                        <Box sx={{ bgcolor: (theme) => theme.palette.primary.main, }}>
                            <Box
                                sx={{
                                    display: 'flex'
                                }}
                            >
                                <Box
                                    sx={{ width: '90%', py: 2, px: 2 }}
                                >
                                    <Typography
                                        variant={'h3'}
                                        sx={{
                                            fontSize: 24,

                                            color: (theme) => theme.palette.secondary.main
                                        }}
                                    >
                                        {isCreateModal ? "Crear Remisión" : "Actualizar Remisión"}
                                    </Typography>
                                    <Typography
                                        variant={'p'}
                                        sx={{
                                            fontSize: 12,
                                            color: (theme) => theme.palette.secondary.main
                                        }}
                                    >
                                        En está sección crearemos la remisión que se liquida por parte del negocio
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{ width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                >
                                    <IconButton
                                        onClick={closeModalHandler}
                                        sx={{
                                            background: (theme) => theme.palette.grey[300],
                                            margin: 0
                                        }}
                                    >
                                        <CloseIcon sx={{ width: 14, height: 14, padding: 0, margin: 0, color: (theme) => theme.palette.primary.main }} />
                                    </IconButton>
                                </Box>
                            </Box>

                        </Box>
                        {/* END Header Modal */}
                    </Box>
                    <Box component="form" onSubmit={submitInvoiceHandler} sx={{ p: 2 }}>

                        {/* Content Modal */}

                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            {/* Seccion 1 */}
                            <Box sx={{ width: '60%', mr: 2 }}>
                                <TextField
                                    name="customerId"
                                    select
                                    label="Nombre del cliente"
                                    value={invoice.customerId}
                                    onChange={inputChangeHandler}
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    sx={{ width: '100%' }}
                                >
                                    {customers.map((customer) => (
                                        <MenuItem key={customer.id} value={customer.id}>
                                            {customer.name}
                                        </MenuItem>
                                    ))}
                                </TextField>


                            </Box>
                            <Box sx={{ width: '40%', diplay: 'flex', flexDirection: 'row' }}>
                                <TextField
                                    name="createdAt"
                                    type={'date'}
                                    label="Fecha"
                                    value={convertDate(invoice.createdAt)}
                                    onChange={inputChangeHandler}
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    sx={{ width: '60%', mr: '5%', backgroundColor: (theme) => theme.palette.grey[100] }}
                                />
                                <TextField
                                    name="invoceNumber"
                                    type={'number'}
                                    label="Remisión"
                                    value={invoice.invoiceNumber}
                                    onChange={inputChangeHandler}
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    sx={{ width: '35%', backgroundColor: (theme) => theme.palette.grey[300] }}
                                    readOnly={true}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
                            <Box sx={{ width: '60%', mr: 2 }}>
                                <TextField
                                    name="zoneId"
                                    select
                                    label="Zona de la finca"
                                    value={invoice.zoneId}
                                    onChange={inputChangeHandler}
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    sx={{ width: '100%' }}
                                >
                                    {zones.map((zone) => (
                                        <MenuItem key={zone.id} value={zone.id}>
                                            {zone.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            {/* <Box sx={{ width: '40%' }}>
                                <TextField name="weight" label="Peso en KG"
                                    variant="outlined"
                                    size="small"
                                    value={invoice.weight}
                                    onChange={inputChangeHandler}
                                    fullWidth
                                    type="number"
                                    margin="dense" />
                            </Box> */}
                        </Box>
                        <Divider sx={{ margin: '15px 0' }} />
                        <Box>
                            {invoice.references?.length > 0 &&
                                <TableContainer component={Paper}
                                    sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledGreenTableCell>Referencia</StyledGreenTableCell>
                                                <StyledGreenTableCell sx={{ textAlign: 'center' }}>Cantidad</StyledGreenTableCell>
                                                <StyledGreenTableCell>Modalidad</StyledGreenTableCell>
                                                <StyledGreenTableCell>Contratista</StyledGreenTableCell>
                                                <StyledGreenTableCell sx={{ textAlign: 'center' }} />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {invoice.references?.map((reference, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {reference.name}
                                                    </TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }} align="right">
                                                        <IconButton aria-label="add"
                                                            sx={{ marginRight: '5px', fontSize: '1rem' }}
                                                            onClick={() => updateReferenceAmount(reference.id, -1, reference.modalidad, reference.contractorId)}>
                                                            <RemoveIcon />
                                                        </IconButton>
                                                        {reference.amount}
                                                        <IconButton aria-label="add"
                                                            sx={{ marginLeft: '5px', fontSize: '1rem' }}
                                                            onClick={() => updateReferenceAmount(reference.id, 1, reference.modalidad, reference.contractorId)}>
                                                            <AddIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {reference.modalidad === "contractorPriceEntresaca" ? "Entresaca" : "Talarasa"}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {reference.contractor}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Button>
                                                            <DeleteIcon color={'warning'}
                                                                onClick={() => removeReferenceFromList(index)} />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2. }}>
                            <Box sx={{ width: '50%', mr: 2 }}>
                                <TextField
                                    select
                                    value={selectedReferenceId}
                                    label="Añadir referencias"
                                    onChange={referenceChangeHandler}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    margin="dense"
                                    sx={{ width: '100%' }}
                                >
                                    {references.map((reference) => (
                                        <MenuItem key={reference.id} value={reference.id}>
                                            {reference.name.toUpperCase()}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box sx={{ width: '50%' }}>
                                <TextField label="Cantidad"
                                    variant="outlined"
                                    size="small"
                                    value={referenceAmount}
                                    onChange={referenceAmountChangeHandler}
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min: 1
                                        }
                                    }}
                                    sx={{ width: '65%', mr: '5%' }}
                                    margin="dense"
                                />
                                <IconButton aria-label="add"
                                    sx={{
                                        marginTop: '5px',
                                        width: '30%',
                                        fontSize: '1rem',
                                        borderRadius: '2rem',
                                        backgroundColor: (theme) => theme.palette.primary.main,
                                        color: (theme) => theme.palette.secondary.main
                                    }}
                                    onClick={addReferenceHandler}
                                >
                                    <AddCircleOutlineIcon sx={{ mr: 1 }} /> Añadir
                                </IconButton>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2. }}>
                            <Box sx={{ width: '50%', mr: 2 }}>
                                <TextField
                                    select
                                    value={refModalidad}
                                    label="Modalidad"
                                    onChange={modalidadChangeHandler}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    margin="dense"
                                    sx={{ width: '100%' }}
                                >
                                    {modalidad.map((modalidad) => (
                                        <MenuItem key={modalidad.label} value={modalidad.value}>
                                            {modalidad.label.toUpperCase()}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box sx={{ width: '50%' }}>
                                <TextField
                                    select
                                    value={selectedContractorId}
                                    label="Añadir contratistas"
                                    onChange={contractorChangeHandler}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    margin="dense"
                                    sx={{ width: '97%' }}
                                >
                                    {contractors.map((contractor) => (
                                        <MenuItem key={contractor.id} value={contractor.id}>
                                            {contractor.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                        </Box>
                        <Divider sx={{ margin: '15px 0' }} />
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            {/* Seccion 5 */}
                            <Box sx={{ width: '50%', mr: 2 }}>
                                <TextField
                                    name="driverId"
                                    select
                                    label="Conductor"
                                    value={invoice.driverId}
                                    onChange={inputChangeHandler}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    margin="dense"
                                >
                                    {drivers.map((driver) => (
                                        <MenuItem key={driver.id} value={driver.id}>
                                            {`${driver.name} / ${driver.identificationType}: ${driver.identification}`}
                                        </MenuItem>
                                    ))}
                                </TextField>

                            </Box>
                            <Box sx={{ width: '50%', diplay: 'flex', flexDirection: 'row' }}>
                                <TextField name="entryTime" label="Hora de entrada"
                                    type="time"
                                    size="small"
                                    sx={{ width: '45%', mr: '5%' }}
                                    value={invoice.entryTime}
                                    onChange={inputChangeHandler}
                                    margin="dense" />
                                <TextField name="departureTime" label="Hora de salida"
                                    size="small"
                                    type="time"
                                    sx={{ width: '45%' }}
                                    value={invoice.departureTime}
                                    onChange={inputChangeHandler}
                                    margin="dense" />
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2. }}>
                            <Box sx={{ width: '50%', mr: 2 }}>
                                <TextField
                                    name="vehicleId"
                                    select
                                    label="Vehiculo"
                                    value={invoice.vehicleId}
                                    onChange={inputChangeHandler}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    margin="dense"
                                >
                                    {vehicles.map((vehicle) => (
                                        <MenuItem key={vehicle.id} value={vehicle.id}>
                                            {vehicle.plate}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box sx={{ width: '50%' }}>
                                <TextField
                                    name="dispatcherId"
                                    select
                                    label="Despachador"
                                    value={invoice.dispatcherId}
                                    onChange={inputChangeHandler}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    margin="dense"
                                >
                                    {dispatchers.map((dispatcher) => (
                                        <MenuItem key={dispatcher.id} value={dispatcher.id}>
                                            {`${dispatcher.name} / ${dispatcher.identificationType}: ${dispatcher.identification}`}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2. }}>
                            <Box sx={{ width: '50%', mr: 2 }}>
                                <TextField name="safeConduct" label="Salvoconducto"
                                    variant="outlined"
                                    size="small"
                                    value={invoice.safeConduct}
                                    onChange={inputChangeHandler}
                                    fullWidth
                                    margin="dense" />
                            </Box>
                            <Box sx={{ width: '50%' }}>
                                <TextField name="destination" label="Destino"
                                    variant="outlined"
                                    size="small"
                                    value={invoice.destination}
                                    onChange={inputChangeHandler}
                                    fullWidth
                                    margin="dense" />
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2. }}>
                            <Box sx={{ width: '50%', mr: 2 }}>
                                <TextField name="freight" label="Valor flete"
                                    variant="outlined"
                                    size="small"
                                    value={invoice.freight}
                                    onChange={inputChangeHandler}
                                    fullWidth
                                    type="number"
                                    margin="dense" />
                            </Box>
                            <Box sx={{ width: '50%' }}>
                                <FormControl>
                                    <FormLabel id="woodType">Especie de madera</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="woodType"
                                        name="woodType"
                                        value={invoice.woodType}
                                        onChange={inputChangeHandler}
                                    >
                                        <FormControlLabel value="patula" control={<Radio size="small" />} label="Patula" />
                                        <FormControlLabel value="cipres" control={<Radio size="small" />} label="Cipres" />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2. }}>
                            <Box sx={{ width: '31%', mr: '3%' }}>
                                <TextField name="order" label="Pedido"
                                    variant="outlined"
                                    size="small"
                                    value={invoice.order}
                                    onChange={inputChangeHandler}
                                    fullWidth
                                    margin="dense" />

                            </Box>
                            <Box sx={{ width: '31%', mr: '3%' }}>
                                <TextField name="delivery" label="Entrega"
                                    variant="outlined"
                                    size="small"
                                    value={invoice.delivery}
                                    onChange={inputChangeHandler}
                                    fullWidth
                                    margin="dense" />

                            </Box>
                            <Box sx={{ width: '31%' }}>
                                <TextField name="bill" label="Factura"
                                    variant="outlined"
                                    size="small"
                                    value={invoice.bill}
                                    onChange={inputChangeHandler}
                                    fullWidth
                                    margin="dense" />
                            </Box>
                        </Box>
                        <Box sx={{ float: 'right', marginTop: '15px', pb: 2 }}>
                            <Button variant="outlined" color="error" onClick={closeModalHandler}
                                sx={{ marginRight: '10px' }}>Cancelar</Button>
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{
                                    width: '40%',
                                    fontSize: '0.9rem',
                                    borderRadius: '2rem',
                                    backgroundColor: (theme) => theme.palette.primary.main,
                                    color: (theme) => theme.palette.secondary.main
                                }}
                            >
                                {MODAL_CONFIRM_BUTTON}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}

export default InvoiceModal;