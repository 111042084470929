import Settings from "../settings/ConfigServer"
import tokenUtils from "../helpers/tokenUtils"

const CREATE_SETTLES_API_URL = `${Settings.urlLocal}/settles/create`
const GET_SETTLES_API_URL = `${Settings.urlLocal}/settles/get`
const GET_SETTLE_REPORT_API_URL = `${Settings.urlServer}/settles/reports`
const GET_SETTLE_REPORT_BY_CUSTOMER_API_URL = `${Settings.urlServer}/settles/reports/by-customer`
const GET_SETTLE_REPORT_BY_CONTRACTOR_API_URL = `${Settings.urlLocal}/settles/reports/by-contractor`

const SettleService = {}

SettleService.createSettle = async (invoices, weekId, settleNumber) => {
    let data = {
        invoices:invoices,
        weekId:weekId,
        settleNumber: settleNumber
    }
    const response = await fetch(CREATE_SETTLES_API_URL, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokenUtils.getToken()}`,
            'role': tokenUtils.getRole() 
        }
    })
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again')
    }
    return response;
}

SettleService.getSettles = async() => {
    const response = await fetch(GET_SETTLES_API_URL, {
        headers: {
            'Authorization': `Bearer ${tokenUtils.getToken()}`
        }
    });
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response
}

SettleService.getReportSettles = async(week) => {
    const response = await fetch(`${GET_SETTLE_REPORT_API_URL}/${week}`, {
        headers: {
            'Authorization': `Bearer ${tokenUtils.getToken()}`
        }
    });
   
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response
}

SettleService.getReportSettleByCustomers = async(week) => {
    const response = await fetch(`${GET_SETTLE_REPORT_BY_CUSTOMER_API_URL}/${week}`, {
        headers: {
            'Authorization': `Bearer ${tokenUtils.getToken()}`
        }
    });
   
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response
}

SettleService.getReportSettleByContractor = async(week) => {
    const response = await fetch(`${GET_SETTLE_REPORT_BY_CONTRACTOR_API_URL}/${week}`, {
        headers: {
            'Authorization': `Bearer ${tokenUtils.getToken()}`
        }
    });
   
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response
}

export default SettleService