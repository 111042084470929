import './App.css';
import {ThemeProvider} from '@mui/material/styles';
import MainLayout from "./components/layouts/mainLayout/MainLayout";
import theme from "./components/layouts/theme";
import MainRoutes from "./components/routes/MainRoutes";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Login from "./components/views/auth/Login";
import useToken from "./hooks/useToken";
import useRole from "./hooks/useRole";

const App = () => {

    const {token, setToken, clearToken} = useToken();
    const {setRole, clearRole} = useRole();

    const setUserHandler = user => {
        setToken(user.token);
        setRole(user.role);
    }

    const clearUserHandler = () => {
        clearToken();
        clearRole();
    }

    return (
        <ThemeProvider theme={theme}>
            {!token && <Login onSetUser={setUserHandler}/>}
            {token &&
                <MainLayout onLogout={clearUserHandler}>
                    <MainRoutes/>
                </MainLayout>}
            <ToastContainer/>
        </ThemeProvider>
    );
}

export default App;
