import Settings from '../settings/ConfigServer';

const LOGIN_API_URL = `${Settings.urlLocal}/login`;

const LoginService = {}

LoginService.login = async (credentials) => {
    const response = await fetch(LOGIN_API_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    });

    if (!response.ok) {
        const error = await response.json()
        throw new Error(error.errorMessage);
    }

    return await response.json();
}

export default LoginService;