import Settings from '../settings/ConfigServer';
import storageUtils from "../helpers/tokenUtils";

const GET_INTERLOCUTOR_TYPES_API_URL = `${Settings.urlLocal}/master/interlocutor-types`;
const GET_ACTIVE_INTERLOCUTOR_TYPES_API_URL = `${Settings.urlLocal}/master/active-interlocutor-types`;
const CREATE_INTERLOCUTOR_TYPE_API_URL = `${Settings.urlLocal}/master/interlocutor-types/create`;
const UPDATE_INTERLOCUTOR_TYPE_API_URL = `${Settings.urlLocal}/master/interlocutor-types/update`;
const REMOVE_INTERLOCUTOR_TYPE_API_URL = `${Settings.urlLocal}/master/interlocutor-types/delete`;

const InterlocutorTypeService = {}

InterlocutorTypeService.getInterlocutorTypes = async () => {
    const response = await fetch(GET_INTERLOCUTOR_TYPES_API_URL, {
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    });
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response.map(interlocutorType => {
        return interlocutorType
    });
}

InterlocutorTypeService.getActiveInterlocutorTypes = async () => {
    const response = await fetch(GET_ACTIVE_INTERLOCUTOR_TYPES_API_URL, {
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    });
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response.map(interlocutorType => {
        return {
            id: interlocutorType.id,
            type: interlocutorType.type
        }
    });
}

InterlocutorTypeService.createInterlocutorType = async (interlocutorType) => {

    const response = await fetch(CREATE_INTERLOCUTOR_TYPE_API_URL, {
        method: 'POST',
        body: JSON.stringify(interlocutorType),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    })

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;
}

InterlocutorTypeService.updateInterlocutorType = async interlocutorType => {

    const response = await fetch(`${UPDATE_INTERLOCUTOR_TYPE_API_URL}/${interlocutorType.id}`, {
        method: 'PUT',
        body: JSON.stringify(interlocutorType),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    })

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;
}

InterlocutorTypeService.removeInterlocutorType = async interlocutorTypeId => {

    const response = await fetch(`${REMOVE_INTERLOCUTOR_TYPE_API_URL}/${interlocutorTypeId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    });

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;

}

export default InterlocutorTypeService;