import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import MainAppBar from "./MainAppBar";
import MainDrawer from "./MainDrawer";
import Footer from "./Footer";
import Container from "@mui/material/Container";

const MainLayout = props => {
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = () => {
        setOpen((open) => !open);
    };

    return (
        <Box sx={{display: 'flex', boxSizing: 'border-box'}}>
            <CssBaseline/>
            <MainAppBar open={open} onToggleDrawer={toggleDrawer} onLogout={props.onLogout}/>
            <MainDrawer open={open} onToggleDrawer={toggleDrawer} onLogout={props.onLogout}/>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) => theme.palette.background.default,
                    flexGrow: 1,
                    position: 'relative',
                    width: '100%'
                }}
            >
                <Container sx={{
                    minHeight: '100vh',
                    padding: '100px 0 50px',
                    marginBottom:6
                }}>
                    {props.children}
                </Container>
                <Footer/>
                {/*<Box component={Footer} sx={{flexShrink: 0}}></Box>*/}
            </Box>
        </Box>
    );
}

export default MainLayout;