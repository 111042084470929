import Settings from '../settings/ConfigServer';
import storageUtils from "../helpers/tokenUtils";

const GET_INVOICES_API_URL = `${Settings.urlLocal}/invoices`;
const GET_INVOICES_BY_DATE_API_URL = `${Settings.urlLocal}/invoices/getdate`
const GET_INVOICES_BY_DATE_ALL_API_URL = `${Settings.urlLocal}/invoices/getalldate`
const GET_INVOICE_REFERENCE_DETAILS_API_URL = `${Settings.urlLocal}/invoices/get-invoice-reference-details`
const CREATE_INVOICE_API_URL = `${Settings.urlLocal}/invoices/create`;
const UPDATE_INVOICE_API_URL = `${Settings.urlLocal}/invoices/update`;
const REMOVE_INVOICE_API_URL = `${Settings.urlLocal}/invoices/delete`;
const UPDATE_INVOICE_WEIGHT_API_URL = `${Settings.urlLocal}/invoices/update-weight`;

const InvoiceService = {}

InvoiceService.getInvoices = async () => {
    const response = await fetch(GET_INVOICES_API_URL, {
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`
        }
    });
    // console.log(response)
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response.map(invoice => {
        return {
            ...invoice,
            references: invoice.references.map(reference => {
                return {
                    id: reference.id,
                    name: reference.name,
                    amount: reference.invoiceReferences?.amount
                }
            })
        }
    });
}

InvoiceService.getInvoice = async invoiceId => {
    const response = await fetch(`${GET_INVOICES_API_URL}/${invoiceId}`, {
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`
        }
    });
    // console.log(response)
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response;
}

InvoiceService.getInvoicesByDate = async(dateTo, dateFrom) => {
    const response = await fetch(`${GET_INVOICES_BY_DATE_API_URL}?dateTo=${dateTo}&dateFrom=${dateFrom}`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`
        }
    });
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response.map(reference => {
        return reference
    });
}

InvoiceService.getInvoicesByDateAll = async(dateTo, dateFrom) => {
    const response = await fetch(`${GET_INVOICES_BY_DATE_ALL_API_URL}?dateTo=${dateTo}&dateFrom=${dateFrom}`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`
        }
    });
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response.map(reference => {
        return reference
    });
}

InvoiceService.getDeatlsInvoiceReferences = async (invoiceId) => {
    const response = await fetch(`${GET_INVOICE_REFERENCE_DETAILS_API_URL}/${invoiceId}`,{
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`
        }
    })
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }
    const data = await response.json();

    return data.response.map(reference => {
        return reference
    });
}

InvoiceService.createInvoice = async invoice => {
    const response = await fetch(CREATE_INVOICE_API_URL, {
        method: 'POST',
        body: JSON.stringify(invoice),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storageUtils.getToken()}`
        }
    })
    
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;
}

InvoiceService.updateInvoice = async invoice => {
    const response = await fetch(`${UPDATE_INVOICE_API_URL}/${invoice.id}`, {
        method: 'PUT',
        body: JSON.stringify(invoice),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    })

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;
}

InvoiceService.removeInvoice = async invoiceId => {

    const response = await fetch(`${REMOVE_INVOICE_API_URL}/${invoiceId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        }
    });

    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;

}

InvoiceService.UpdateInvoiceWeight = async (invoiceId, weight) => {

    const response = await fetch(`${UPDATE_INVOICE_WEIGHT_API_URL}/${invoiceId}`, {
        method: 'POST',
        headers: {
            'Content-type':'application/json',
            'Authorization': `Bearer ${storageUtils.getToken()}`,
            'role': storageUtils.getRole()
        },
        body:JSON.stringify({weight:weight})
    });
    // console.log(response)
    if (!response.ok) {
        throw new Error('Something went wrong!, please try again');
    }

    return response;

}

export default InvoiceService;