import {format} from "date-fns";

const capitalizeFirstLetter = (value) => {
    if (value && value.length > 1) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;
}

const convertDate = (date) => {
    if (date) {
        let day = new Date(date)
        let numberDay = day.getDate()
        let month = new Date(date)
        let numberMonth = month.getMonth()
        let year = new Date(date)
        let numberYear = year.getFullYear()
        return format(new Date(numberYear, numberMonth, numberDay+1), 'yyyy-MM-dd')
    }
    return date;
}

const convertDate2 = (date) => {
    if (date) {
        let day = new Date(date)
        let numberDay = day.getDate()
        let month = new Date(date)
        let numberMonth = month.getMonth()
        let year = new Date(date)
        let numberYear = year.getFullYear()
        return format(new Date(numberYear, numberMonth, numberDay), 'yyyy-MM-dd')
    }
    return date;
}

const convertDate3 = (date) => {
    if (date) {
        let day = new Date(date)
        let numberDay = day.getDate()
        let month = new Date(date)
        let numberMonth = month.getMonth()
        let year = new Date(date)
        let numberYear = year.getFullYear()
        return format(new Date(numberYear, numberMonth, numberDay - 1), 'yyyy-MM-dd')
    }
    return date;
}

const getWeekNumberCurrent = () => {
    let currentDate = new Date();
    let startDate = new Date(currentDate.getFullYear(), 0, 1);
    let days = Math.floor((currentDate - startDate) /
        (24 * 60 * 60 * 1000));

    let weekNumber = Math.ceil(days / 7);
    return weekNumber
}
const convertStartDateWithTime = (date) => {
    if (date) {
        let day = new Date(date)
        let numberDay = day.getDate()
        let month = new Date(date)
        let numberMonth = month.getMonth()
        let year = new Date(date)
        let numberYear = year.getFullYear()
        return format(new Date(numberYear, numberMonth, numberDay+1), `yyyy-MM-dd 00:00:00`)
    }
    return date;
}

const convertEndDateWithTime = (date) => {
    if (date) {
        let day = new Date(date)
        let numberDay = day.getDate()
        let month = new Date(date)
        let numberMonth = month.getMonth()
        let year = new Date(date)
        let numberYear = year.getFullYear()
        return format(new Date(numberYear, numberMonth, numberDay+1), 'yyyy-MM-dd 23:59:50')
    }
    return date;
}

const formatPrice = (price) => {
    if (price) {
        return `$ ${(price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
    }
    return price;
}

const convertTime = time => {
    if (time) {
        return time.slice(0, 5);
    }
}

export {capitalizeFirstLetter, convertDate, convertDate2, formatPrice, convertTime, getWeekNumberCurrent, convertEndDateWithTime, convertStartDateWithTime, convertDate3}