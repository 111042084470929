import React, { useCallback, useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, Font, PDFDownloadLink } from '@react-pdf/renderer';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import InvoiceService from "../../../services/invoice.service";
import { convertDate2, convertTime, formatPrice } from "../../../helpers/utils";
import Button from "@mui/material/Button";
import InvoiceStates from "../../../helpers/InvoiceStates";

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        padding: '10px',
        marginLeft: '30px'
    },
    mainWidth: {
        width: '90%',
    },
    fullWidth: {
        width: '100%'
    },
    border: {
        flexDirection: 'row',
        border: '2px solid black',
    },
    row: {
        flexDirection: 'row',
    },
    twoColumn: {
        justifyContent: 'space-between'
    },
    section: {
        flexGrow: 1
    },
    borderSection: {
        borderLeft: '2px solid black',
        borderRight: '2px solid black'
    },
    borderRight: {
        borderRight: '2px solid black'
    },
    borderBottom: {
        borderBottom: '2px solid black'
    },
    borderTop: {
        borderTop: '2px solid black'
    },
    imageBox: {
        width: '18%',
        height: '100px'
    },
    centerBox: {
        padding: '2px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    mainTablePadding: {
        padding: '2px'
    },
    tablePadding: {
        padding: '5px'
    },
    pdfTitle: {
        fontSize: '14px',
        fontFamily: 'Roboto'
    },
    paragraphContainer: {
        marginBottom: '10px',
    },
    paragraph: {
        fontSize: '12px',
    },
    centerText: {
        textAlign: 'center'
    },
    dataMarginLeft: {
        marginLeft: '15px'
    },
    bold: {
        fontFamily: 'Roboto'
    }
});

Font.register({
    family: 'Roboto',
    fonts: [{ src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf" }]
})

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    paddingBottom: '10px',
    maxHeight: '700px'
};

const PDFDoc = props => {
    let invoice = props.invoice
    let invoiceDetails = props.invoiceDetails
    let split = props.split
    return (
        <Document id={"DocPdf"} title={`Remisión ${invoice.invoiceNumber}`}>
            <Page size="A4" style={styles.page}>
                {/*Header*/}
                <View style={[styles.mainWidth, styles.border]}>
                    <View style={[styles.imageBox]}>
                        <Image
                            style={styles.tablePadding}
                            src="logo.jpg"
                        />
                    </View>
                    <View style={[styles.section, styles.borderSection, { maxWidth: '70%' }]}>
                        <View style={[styles.centerBox, styles.borderBottom, { height: '60px' }]}>
                            <Text style={[styles.pdfTitle]}>INVERSIONES
                                FORESTALES LA CABAÑA S.A.S.</Text>
                        </View>
                        <View style={[styles.centerBox, { height: '40px' }]}>
                            <Text style={styles.pdfTitle}>REMISIÓN DESPACHO DE MADERA</Text>
                        </View>
                    </View>
                    <View style={[styles.section, { maxWidth: '20%' }]}>
                        <View style={[styles.tablePadding, styles.borderBottom]}>
                            <Text style={[styles.paragraph, styles.bold]}>CÓDIGO:</Text>
                            <Text style={[styles.paragraph, styles.bold]}>EXTR-FO-001</Text>
                        </View>
                        <View style={[styles.tablePadding, styles.borderBottom, { height: '35px' }]}>
                            <Text style={[styles.paragraph, styles.bold]}>Versión: 1.0</Text>
                        </View>
                        <View style={[styles.tablePadding]}>
                            <Text style={[styles.paragraph, styles.bold]}>Página 1 de 1</Text>
                        </View>
                    </View>
                </View>
                {/*Fin header*/}
                {/*Datos parte 1*/}
                <View style={[styles.mainWidth, styles.row, styles.twoColumn, { marginTop: '20px' }]}>
                    <View>
                        <View style={styles.paragraphContainer}>
                            <Text style={[styles.paragraph]}>
                                <Text style={styles.bold}>CLIENTE:</Text> {invoice.customer?.name}
                            </Text>

                        </View>
                        <View style={styles.paragraphContainer}>
                            <Text style={styles.paragraph}>
                                <Text style={styles.bold}>ZONA DE LA FINCA:</Text> {invoice.zone?.name}
                            </Text>
                        </View>
                        <View style={styles.paragraphContainer}>
                            <Text
                                style={styles.paragraph}>
                                <Text style={styles.bold}>CONTRATISTA: </Text>
                                {invoiceDetails && invoiceDetails[0]?.interlocutor?.name}
                            </Text>
                        </View>
                    </View>
                    <View style={[{ marginRight: '50px' }]}>
                        <View style={styles.paragraphContainer}>
                            <Text style={[styles.paragraph, styles.bold]}>REMISIÓN
                                # {invoice.invoiceNumber}</Text>
                        </View>
                        <View style={styles.paragraphContainer}>
                            <Text style={styles.paragraph}>
                                <Text style={styles.bold}>FECHA:</Text> {convertDate2(invoice.createdAt)}
                            </Text>
                        </View>
                        <View style={[styles.paragraphContainer]}>
                            <Text style={styles.paragraph}>
                                <Text style={styles.bold}>SALVOCONDUCTO:</Text> {invoice.safeConduct}
                            </Text>
                        </View>
                    </View>
                </View>
                {/*Fin Datos parte 1*/}
                {/*Especie de madera tabla*/}
                <View style={[styles.row, styles.border, { width: '320px', marginTop: '20px' }]}>
                    <View style={[{ width: '180px' }]}>
                        <Text
                            style={[
                                styles.paragraph,
                                styles.centerText,
                                styles.borderBottom,
                                styles.borderRight,
                                styles.bold]}>
                            ESPECIE DE MADERA
                        </Text>
                        <View style={[styles.row, { padding: '10px' }, styles.borderRight]}>
                            {
                                invoice.woodType === "cipres" && <><Text style={[styles.paragraph]}>Patula ____</Text> <Text style={[styles.paragraph, { marginLeft: '30px' }]}>Cipres _X_</Text></>

                            }
                            {
                                invoice.woodType === "patula" && <><Text style={[styles.paragraph]}>Patula _X_</Text> <Text style={[styles.paragraph, { marginLeft: '30px' }]}>Cipres ____</Text></>

                            }

                        </View>
                    </View>
                    <View style={[{ width: '140px' }]}>
                        <Text style={[
                            styles.paragraph,
                            styles.centerText,
                            styles.borderBottom,
                            styles.bold]}>
                            PESO
                        </Text>
                        <Text style={[
                            styles.paragraph,
                            styles.centerText,
                            { padding: '10px' }]}>
                            {invoice.weight}
                        </Text>
                    </View>
                </View>
                {/*Fin especie de madera tabla*/}
                {/*Tabla referencias*/}
                <View style={[styles.mainWidth, { marginTop: '30px' }, styles.row, styles.twoColumn]}>
                    <View style={[split ? { width: '49%' } : { width: '100%' }, styles.border]}>
                        <View style={[{ width: '80%' }, styles.borderRight]}>
                            <Text
                                style={[
                                    styles.paragraph,
                                    styles.tablePadding,
                                    styles.bold,
                                    { paddingLeft: '20px' }]}>
                                DESCRIPCIÓN
                            </Text>
                            {invoiceDetails?.map((reference, i) => (
                                (!split || i < (invoiceDetails.length / 2)) &&
                                <Text key={i}
                                    style={[styles.paragraph, styles.tablePadding, styles.borderTop]}>
                                    {reference.reference.name}
                                </Text>
                            ))}
                        </View>
                        <View style={[{ width: '20%' }, styles.centerText]}>
                            <Text style={[styles.paragraph, styles.tablePadding, styles.bold]}>CANT.</Text>
                            {invoiceDetails?.map((reference, i) => (
                                (!split || i < (invoiceDetails.length / 2)) &&
                                <Text key={i}
                                    style={[styles.paragraph, styles.tablePadding, styles.borderTop]}>
                                    {reference?.amount}
                                </Text>
                            ))}
                        </View>
                    </View>
                    {split &&
                        <View style={[{ width: '49%' }, styles.border]}>
                            <View style={[{ width: '80%' }, styles.borderRight]}>
                                <Text
                                    style={[
                                        styles.paragraph,
                                        styles.tablePadding,
                                        styles.bold,
                                        styles.borderBottom,
                                        { paddingLeft: '20px' }]}>
                                    DESCRIPCIÓN
                                </Text>
                                {invoiceDetails?.map((reference, i) => (
                                    i >= (invoiceDetails.length / 2) &&
                                    <Text key={i}
                                        style={[styles.paragraph, styles.tablePadding, styles.borderBottom]}>
                                        {reference.reference.name}
                                    </Text>
                                ))}
                            </View>
                            <View style={[{ width: '20%' }, styles.centerText]}>
                                <Text
                                    style={[
                                        styles.paragraph,
                                        styles.tablePadding,
                                        styles.bold,
                                        styles.borderBottom]}>
                                    CANT.
                                </Text>
                                {invoiceDetails?.map((reference, i) => (
                                    i >= (invoiceDetails.length / 2) &&
                                    <Text key={i}
                                        style={[styles.paragraph, styles.tablePadding, styles.borderBottom]}>
                                        {reference?.amount}
                                    </Text>
                                ))}
                            </View>
                        </View>
                    }

                </View>
                {/*Fin referencias*/}
                {/*Datos parte 2*/}
                <View style={[{ marginTop: '20px' }, styles.row]}>
                    <View style={{ width: '250px' }}>
                        <View style={[styles.row, styles.paragraphContainer]}>
                            <Text style={[styles.paragraph]}>
                                <Text style={styles.bold}>CONDUCTOR:</Text> {invoice.driver?.name}
                            </Text>
                        </View>
                        <View style={[styles.row, styles.paragraphContainer]}>
                            <Text style={[styles.paragraph]}>
                                <Text style={styles.bold}>DEPACHADO POR:</Text> {invoice.dispatcher?.name}
                            </Text>
                        </View>
                        <View style={[styles.row, styles.paragraphContainer]}>
                            <Text style={[styles.paragraph]}>
                                <Text style={styles.bold}>VEHICULO PLACA:</Text> {invoice.vehicle?.plate}
                            </Text>
                        </View>
                        <View style={[styles.row, styles.paragraphContainer]}>
                            <Text style={[styles.paragraph]}>
                                <Text style={styles.bold}>DESTINO:</Text> {invoice.destination}
                            </Text>
                        </View>
                    </View>
                    <View>
                        <View style={[styles.row, styles.paragraphContainer]}>
                            <Text style={[styles.paragraph, styles.dataMarginLeft]}>
                                <Text style={styles.bold}>{invoice.driver?.identificationType}:</Text>
                                {invoice.driver?.identification}
                            </Text>
                        </View>
                        <View style={[styles.row, styles.paragraphContainer]}>
                            <Text style={[styles.paragraph, styles.dataMarginLeft]}>
                                <Text style={styles.bold}>HORA ENTRADA: </Text>
                                {convertTime(invoice.entryTime)}
                            </Text>
                        </View>
                        <View style={[styles.row, styles.paragraphContainer]}>
                            <Text style={[styles.paragraph, styles.dataMarginLeft]}>
                                <Text style={styles.bold}>HORA SALIDA: </Text>
                                {convertTime(invoice.departureTime)}
                            </Text>
                        </View>
                        <View style={[styles.row, styles.paragraphContainer]}>
                            <Text style={[styles.paragraph, styles.dataMarginLeft]}>
                                <Text style={styles.bold}>VALOR
                                    FLETES:</Text> {formatPrice(invoice.freight)}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={[styles.row, { marginTop: '10px' }]}>
                    <View>
                        <Text style={[styles.paragraph, styles.paragraphContainer, styles.bold]}>
                            PARA USO EXLUSIVO DE CONTABILIDAD
                        </Text>
                        <Text style={[styles.paragraph, styles.paragraphContainer]}>
                            <Text style={styles.bold}>PEDIDO:</Text> {invoice.order}
                        </Text>
                        <Text style={[styles.paragraph, styles.paragraphContainer]}>
                            <Text style={styles.bold}>ENTREGA:</Text> {invoice.delivery}
                        </Text>
                        <Text style={[styles.paragraph, styles.paragraphContainer]}>
                            <Text style={styles.bold}>FACTURA:</Text> {invoice.bill}
                        </Text>
                    </View>
                    <View style={{ margin: '80px 0 0 80px' }}>
                        <Text style={[styles.paragraph]}>______________________</Text>
                        <Text style={[styles.paragraph, styles.bold]}>FIRMA Y SELLO CLIENTE</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

const InvoiceModalPDF = props => {
    const [invoiceId, setInvoiceId] = useState(props.invoiceId)
    const [invoice, setInvoice] = useState({});
    const [invoiceDetails, setInvoiceDetails] = useState([])
    const [split, setSplit] = useState(false);

    // console.log(invoice);

    const fetchInvoice = useCallback(async () => {
        try {
            if (invoiceId) {
                const invoiceDetails = await InvoiceService.getInvoice(invoiceId);
                // console.log(invoiceDetails.invoiceDetail)
                if (invoiceDetails.references?.length > 9) {
                    setSplit(true);
                } else {
                    setSplit(false);
                }
                setInvoice(invoiceDetails.invoice);
                setInvoiceDetails(invoiceDetails.invoiceDetail)
                // newTotalReferences(invoiceDetails.invoiceDetail)
            }
        } catch (error) {
            console.error(error.message);
        }
    }, [invoiceId])

    // const newTotalReferences = (invoiceDetails) => {

    //     //Covert array to simplet array of referenceId

    //     let newDataArray = invoiceDetails.map((ref) => {
    //         return ref.referenceId
    //     })

    //     console.log(newDataArray)

    //     let result = newDataArray.filter((item,index)=>{
    //         return newDataArray.indexOf(item) === index;
    //     })

    //     console.log(result)

    //     // const result = newDataArray.reduce((acc,ref)=>{
    //     //     if(!acc.includes(ref)){
    //     //       acc.push(ref);
    //     //     }
    //     //     return acc;
    //     // },[])

    //     // console.log(result)

    //     // let result = invoiceDetails.filter((ref, index)=>{
    //     //     console.log(invoiceDetails[index].reference.name.indexOf(ref.reference.name) === index)
    //     // })
    // }

    const closeModalHandler = () => props.onClose();

    useEffect(() => {
        if (props.open) void fetchInvoice()
    }, [fetchInvoice, invoiceId, props.open])

    useEffect(() => {
        setInvoiceId(props.invoiceId)
    }, [props.invoiceId])

    const printPDF = async () => {
        if (invoice.state === 'Liquidado') {
            window.frames["pdfIframe"].focus();
            window.frames["pdfIframe"].print();
        } else {
            const invoiceWithPrintedState = {
                id: invoiceId,
                state: InvoiceStates.printed
            }
            await InvoiceService.updateInvoice(invoiceWithPrintedState)
            window.frames["pdfIframe"].focus();
            window.frames["pdfIframe"].print();
        }
    }

    return (
        <Modal
            open={props.open}
            onClose={closeModalHandler}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Box component={PDFViewer} id='pdfIframe' name="pdfIframe" sx={{ width: '100%', height: '600px' }}>
                    <PDFDoc
                        invoice={invoice}
                        invoiceDetails={invoiceDetails}
                        split={split}
                    />
                </Box>
                <Box sx={{ textAlign: 'center', marginTop: '5px' }}>
                    <Button variant="contained" onClick={printPDF} sx={{mr:2}}>IMPRIMIR</Button>
                    <PDFDownloadLink document={<PDFDoc invoice={invoice} invoiceDetails={invoiceDetails} split={split}/>} fileName={`remision_${invoice.invoiceNumber}.pdf`}>
                        <Button variant="contained">DescargarPDF</Button>
                    </PDFDownloadLink>
                </Box>
            </Box>
        </Modal>
    );
};

export default InvoiceModalPDF;