import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import StyledTableCell from "../../layouts/table/StyledTableCell";
import StyledTableRow from "../../layouts/table/StyledTableRow";
import {Card, CardContent, CardHeader} from "@mui/material";
import Typography from "@mui/material/Typography";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Button from "@mui/material/Button";
import React, {useCallback, useEffect, useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ZoneModal from "./ZoneModal";
import DeleteItemModal from "../DeleteItemModal";
import ZoneService from "../../../services/zone.service";
import {toast} from "react-toastify";


const initialZone = {
    id: '',
    name: '',
    active: true
}

const ZoneList = () => {

    const [zones, setZones] = useState([]);
    const [selectedZone, setSelectedZone] = useState(initialZone)
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    //modals
    const [openCreateZoneModal, setOpenCreateZoneModal] = useState(false);
    const [openEditZoneModal, setOpenEditZoneModal] = useState(false);
    const [openDeleteZoneModal, setOpenDeleteZoneModal] = useState(false);

    const fetchZonesHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null)
        try {
            const zoneList = await ZoneService.getZones();
            console.log(zoneList);
            setZones(zoneList);

        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false);
    }, []);

    const removeZoneHandler = async () => {
        try {
            await ZoneService.removeZone(selectedZone.id);
            toast.success('Se ha eliminado correctamente', {delay: 1000});
            toggleModal('remove', false)
        } catch (e) {
            console.error(e);
            toast.error('There was an error trying to remove zones', {delay: 1000});
        }
    };

    const openCreateZoneHandler = () => {
        setSelectedZone(initialZone)
        toggleModal('create', true);
    }

    const openEditZoneHandler = (zone) => {
        setSelectedZone(zone)
        toggleModal('edit', true);
    }

    const openRemoveZoneHandler = (zone) => {
        setSelectedZone(zone)
        toggleModal('remove', true);
    }

    const toggleModal = (modalType, open) => {
        switch (modalType) {
            case 'create':
                setOpenCreateZoneModal(open);
                break;
            case 'edit':
                setOpenEditZoneModal(open);
                break;
            case 'remove':
                setOpenDeleteZoneModal(open);
                break;
            default:
                console.error(`Modal type ${modalType} is not valid`);
        }

    }

    useEffect(() => {
        if (!openCreateZoneModal && !openDeleteZoneModal && !openEditZoneModal) {
            void fetchZonesHandler();
        }

    }, [fetchZonesHandler, openCreateZoneModal, openDeleteZoneModal, openEditZoneModal])


    return (
        <>
            <Card>
                <CardHeader
                    title={
                        <Typography variant='h4'>
                            Zonas
                        </Typography>
                    }
                    action={
                        <Button variant="outlined" startIcon={<ControlPointIcon/>}
                                onClick={openCreateZoneHandler}>
                            Añadir nuevo
                        </Button>
                    }
                >
                </CardHeader>
                <CardContent>
                    {isLoading && zones.length <= 0 && <p>Cargando Zonas...</p>}
                    {!isLoading && !error && zones.length === 0 && <p>No se han creado zonas.</p>}
                    {error && <p> {error} </p>}
                    {zones.length > 0 &&
                        <TableContainer component={Paper} sx={{maxWidth: '100%'}}>
                            <Table sx={{minWidth: 400}}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Nombre zona</StyledTableCell>
                                        <StyledTableCell align="right">Estado</StyledTableCell>
                                        <StyledTableCell align="right"/>
                                        <StyledTableCell align="right"/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {zones.map((zone) => (
                                        <StyledTableRow
                                            key={zone.id}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {zone.name}
                                            </TableCell>
                                            <TableCell align="right">{zone.active ? 'Activo' : 'Inactivo'}</TableCell>
                                            <TableCell align="right">
                                                <Button onClick={() => openEditZoneHandler(zone)}>
                                                    <EditIcon/>
                                                </Button>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button onClick={() => openRemoveZoneHandler(zone)}>
                                                    <DeleteIcon color={'warning'}/>
                                                </Button>
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                </CardContent>
            </Card>

            {/*modals*/}
            <ZoneModal open={openCreateZoneModal}
                       modalType="CREATE"
                       zone={selectedZone}
                       onClose={() => toggleModal('create', false)}
                       zonesSize={zones.length}/>
            <ZoneModal open={openEditZoneModal}
                       modalType="UPDATE"
                       zone={selectedZone}
                       onClose={() => toggleModal('edit', false)}
                       zonesSize={zones.length}/>
            <DeleteItemModal open={openDeleteZoneModal}
                             onClose={() => toggleModal('remove', false)}
                             onRemove={removeZoneHandler}
                             itemName={selectedZone.name}
                             item={"zona"}/>
        </>

    )
}

export default ZoneList;