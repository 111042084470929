import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import {useCallback, useEffect, useState} from "react";
import {convertDate} from "../../../helpers/utils";
import interlocutorService from "../../../services/interlocutor.service";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    paddingBottom: '10px'
};

const inputStyle = {
    marginBottom: '15px'
}

const UserViewModal = props => {

    const [user, setUser] = useState({...props.user})
    const [employeesList, setEmployeesList] = useState([]);

    const inputChangeHandler = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setUser(user => {
            return {
                ...user,
                [name]: value
            }
        })
    }
    const fetchEmployeesHandler = useCallback(async () => {
        try {
            const employees = await interlocutorService.getEmployees();
            setEmployeesList(employees);
        } catch (error) {
            console.error(error.message);
        }
    }, []);

    useEffect(() => {
        setUser({...props.user})
    }, [props.user]);

    useEffect(() => {
        void fetchEmployeesHandler();
    }, [fetchEmployeesHandler])

    const closeModalHandler = () => props.onClose();

    return (
        <Modal
            open={props.open}
            onClose={closeModalHandler}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{marginBottom: '30px'}}>
                    Empleado
                </Typography>
                <form>
                    <TextField
                        name="employeeId"
                        select
                        label="Identificación Fiscal"
                        onChange={inputChangeHandler}
                        value={user.employeeId}
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={inputStyle}
                        InputProps={{
                            readOnly: true,
                        }}
                    >
                        {employeesList.map((employee) => (
                            <MenuItem key={employee.id} value={employee.id}>
                                {`${employee.identificationType}: ${employee.identification}`}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        name="employeeId"
                        select
                        label="Nombre"
                        onChange={inputChangeHandler}
                        value={user.employeeId}
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={inputStyle}
                        InputProps={{
                            readOnly: true,
                        }}
                    >
                        {employeesList.map((employee) => (
                            <MenuItem key={employee.id} value={employee.id}>
                                {`${employee.name}`}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        name="employeeId"
                        select
                        label="Fecha de ingreso"
                        onChange={inputChangeHandler}
                        value={user.employeeId}
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={inputStyle}
                        InputProps={{
                            readOnly: true,
                        }}
                    >
                        {employeesList.map((employee) => (
                            <MenuItem key={employee.id} value={employee.id}>
                                {`${convertDate(employee.startDate)}`}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        name="employeeId"
                        select
                        label="Estado"
                        onChange={inputChangeHandler}
                        value={user.employeeId}
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={inputStyle}
                        InputProps={{
                            readOnly: true,
                        }}
                    >
                        {employeesList.map((employee) => (
                            <MenuItem key={employee.id} value={employee.id}>
                                {`${employee.active? 'Activo': 'Inactivo'}`}
                            </MenuItem>
                        ))}
                    </TextField>

                    <Box sx={{float: 'right'}}>
                        <Button variant="outlined" color="success" onClick={closeModalHandler}
                                sx={{marginRight: '10px'}}>Aceptar</Button>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
}

export default UserViewModal;